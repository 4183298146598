import exterior_full_1 from '../assets/images/exterior/exterior-1.webp'
import exterior_full_2 from '../assets/images/exterior/exterior-2.webp'
import exterior_full_3 from '../assets/images/exterior/exterior-3.webp'
import exterior_full_4 from '../assets/images/exterior/exterior-4.webp'
import exterior_full_5 from '../assets/images/exterior/exterior-5.webp'
import exterior_full_6 from '../assets/images/exterior/exterior-6.webp'
import exterior_thumb_1 from '../assets/images/exterior/thumbs/exterior-1.webp'
import exterior_thumb_2 from '../assets/images/exterior/thumbs/exterior-2.webp'
import exterior_thumb_3 from '../assets/images/exterior/thumbs/exterior-3.webp'
import exterior_thumb_4 from '../assets/images/exterior/thumbs/exterior-4.webp'
import exterior_thumb_5 from '../assets/images/exterior/thumbs/exterior-5.webp'
import exterior_thumb_6 from '../assets/images/exterior/thumbs/exterior-6.webp'

import interior_full_1 from '../assets/images/building/renderings/Lobby.webp'
import interior_full_2 from '../assets/images/building/renderings/Elevators.webp'
import interior_full_3 from '../assets/images/building/renderings/Gym-Fitness.webp'
import interior_full_4 from '../assets/images/building/renderings/Yoga-Studio.webp'
import interior_full_5 from '../assets/images/building/renderings/Basketball.webp'
import interior_full_6 from '../assets/images/building/renderings/Squash-Court.webp'
import interior_full_7 from '../assets/images/building/renderings/Games-Room.webp'
import interior_full_8 from '../assets/images/building/renderings/Pool-Lounge.webp'
import interior_full_9 from '../assets/images/building/renderings/Pool.webp'
import interior_full_10 from '../assets/images/building/renderings/Golf-Simulator.webp'
import interior_full_11 from '../assets/images/building/renderings/Party-Room.webp'
import interior_full_12 from '../assets/images/building/renderings/Rooftop-Terrace.webp'
import interior_full_13 from '../assets/images/building/renderings/Dining.webp'
import interior_full_14 from '../assets/images/building/renderings/Pet-Grooming.webp'
import interior_full_15 from '../assets/images/building/renderings/Theatre-Room.webp'
import interior_thumb_1 from '../assets/images/building/renderings/thumbs/Lobby.webp'
import interior_thumb_2 from '../assets/images/building/renderings/thumbs/Elevators.webp'
import interior_thumb_3 from '../assets/images/building/renderings/thumbs/Gym-Fitness.webp'
import interior_thumb_4 from '../assets/images/building/renderings/thumbs/Yoga-Studio.webp'
import interior_thumb_5 from '../assets/images/building/renderings/thumbs/Basketball.webp'
import interior_thumb_6 from '../assets/images/building/renderings/thumbs/Squash-Court.webp'
import interior_thumb_7 from '../assets/images/building/renderings/thumbs/Games-Room.webp'
import interior_thumb_8 from '../assets/images/building/renderings/thumbs/Pool-Lounge.webp'
import interior_thumb_9 from '../assets/images/building/renderings/thumbs/Pool.webp'
import interior_thumb_10 from '../assets/images/building/renderings/thumbs/Golf-Simulator.webp'
import interior_thumb_11 from '../assets/images/building/renderings/thumbs/Party-Room.webp'
import interior_thumb_12 from '../assets/images/building/renderings/thumbs/Rooftop-Terrace.webp'
import interior_thumb_13 from '../assets/images/building/renderings/thumbs/Dining.webp'
import interior_thumb_14 from '../assets/images/building/renderings/thumbs/Pet-Grooming.webp'

import floorplan_full_1 from '../assets/images/building/floorplans/2nd.webp'
import floorplan_full_2 from '../assets/images/building/floorplans/7th.webp'
import floorplan_full_3 from '../assets/images/building/floorplans/Roof.webp'
import floorplan_thumb_1 from '../assets/images/building/floorplans/thumbs/2nd.webp'
import floorplan_thumb_2 from '../assets/images/building/floorplans/thumbs/7th.webp'
import floorplan_thumb_3 from '../assets/images/building/floorplans/thumbs/Roof.webp'

const Renderings = {
    exterior: [
        {
            full: exterior_full_1,
            thumb: exterior_thumb_1
        },
        {
            full: exterior_full_2,
            thumb: exterior_thumb_2
        },
        {
            full: exterior_full_3,
            thumb: exterior_thumb_3
        },
        {
            full: exterior_full_4,
            thumb: exterior_thumb_4
        },
        {
            full: exterior_full_5,
            thumb: exterior_thumb_5
        },
        {
            full: exterior_full_6,
            thumb: exterior_thumb_6
        },
    ],
    interior: [
        {
            full: interior_full_1,
            thumb: interior_thumb_1,
            text: 'the lobby'
        },
        {
            full: interior_full_2,
            thumb: interior_thumb_2,
            text: 'lobby lounge'
        },
        {
            full: interior_full_3,
            thumb: interior_thumb_3,
            text: 'fitness studio',
        },
        {
            full: interior_full_4,
            thumb: interior_thumb_4,
            text: 'yoga room'
        },
        {
            full: interior_full_5,
            thumb: interior_thumb_5,
            text: 'basketball court'
        },
        {
            full: interior_full_6,
            thumb: interior_thumb_6,
            text: 'squash court'
        },
        {
            full: interior_full_7,
            thumb: interior_thumb_7,
            text: 'games and billiards room'
        },
        {
            full: interior_full_8,
            thumb: interior_thumb_8,
            text: 'pool lounge'
        },
        {
            full: interior_full_9,
            thumb: interior_thumb_9,
            text: 'outdoor pool'
        },
        {
            full: interior_full_10,
            thumb: interior_thumb_10,
            text: 'golf simulator',

        },
        {
            full: interior_full_11,
            thumb: interior_thumb_11,
            text: '5th club party room'
        },
        {
            full: interior_full_12,
            thumb: interior_thumb_12,
            text: '5th club rooftop terrace'
        },
        {
            full: interior_full_13,
            thumb: interior_thumb_13,
            text: 'private dining'
        },
        {
            full: interior_full_14,
            thumb: interior_thumb_14,
            text: 'pet grooming room'
        }
    ],
    floorplans: [
        {
            full: floorplan_full_1,
            thumb: floorplan_thumb_1,
            text: '2nd floor',
            image_map: [
                {
                    shape: "poly",
                    coords: "1190,55,1990,55,1990,1039,1190,1039",
                    alt: 'Basketball Court',
                    full: interior_full_5
                }
            ]
        },
        {
            full: floorplan_full_2,
            thumb: floorplan_thumb_2,
            text: '7th floor',
            image_map: [
                {
                    shape: "poly",
                    coords: "1164,295,1307,295,1307,417,1164,417",
                    alt: 'Golf Simulator',
                    full: interior_full_10
                },
                {
                    shape: "poly",
                    coords: "1164,83,1311,83,1311,286,1164,286",
                    alt: 'Yoga Studio',
                    full: interior_full_4
                },
                {
                    shape: "poly",
                    coords: "1165,563,1300,563,1300,899,1165,899",
                    alt: 'Gym',
                    full: interior_full_3
                },
                {
                    shape: "poly",
                    coords: "1115,1134,1628,1134,1628,1327,1115,1327",
                    alt: 'Pool',
                    full: interior_full_9
                },
                {
                    shape: "poly",
                    coords: "1432,705,1593,705,1593,899,1432,899",
                    alt: 'Pool Lounge',
                    full: interior_full_8
                },
                {
                    shape: "poly",
                    coords: "1435,566,1594,566,1594,687,1435,687",
                    alt: 'Theatre Room',
                    full: interior_full_15
                },
                {
                    shape: "poly",
                    coords: "1370,81,1594,81,1594,287,1370,287",
                    alt: 'Games Room',
                    full: interior_full_7
                }
            ]
        },
        {
            full: floorplan_full_3,
            thumb: floorplan_thumb_3,
            text: '5th club rooftop',
            image_map: [
                {
                    shape: "poly",
                    coords: "1097,1042,1773,1042,1773,1320,1097,1320",
                    alt: 'Rooftop Terrace',
                    full: interior_full_12
                },
                {
                    shape: "poly",
                    coords: "1478,46,1709,46,1709,1035,1478,1035",
                    alt: 'Party Room',
                    full: interior_full_11
                }
            ]
        }
    ]
}

export default Renderings