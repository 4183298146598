import React, { useEffect } from 'react';
import Page from './Page'
import ImageOne from '../../assets/images/exterior/exterior-1_cropped.webp'
import ImageTwo from '../../assets/images/exterior/exterior-3_cropped.webp'
import { Fade } from 'react-slideshow-image';

function Screensaver() {
    const fadeImages = [
        ImageOne,
        ImageTwo,
    ];

    const fadeProperties = {
        duration: 10000,
        transitionDuration: 500,
        infinite: true,
        indicators: false,
        arrows: false,
        cssClass: "slide-test",
    }

    return (
        <div className="screensaver">
            <div className="slide-container">
                <Fade {...fadeProperties}>
                    <div className="each-fade">
                        <img src={fadeImages[0]} />
                    </div>
                    <div className="each-fade">
                        <img src={fadeImages[1]} />
                    </div>
                </Fade>
            </div>
        </div>
    )
}

export default Screensaver