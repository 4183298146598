import React, { useState, useRef, useEffect } from 'react'
import Page from './Page'
import Amenities from '../../assets/images/amenities.webp'
import Legend from '../../assets/images/legend.webp'
import Arrow from '../../assets/images/icons/arrow.webp'
import Swipe from '../../assets/images/swipe.png'

function Map() {
    const [swipeHidden, setSwipeHidden] = useState(false)
    const alertHidden = swipeHidden ? 'hidden' : ''

    const mapRef = useRef()

    useEffect(() => {
        mapRef.current.scrollLeft = 500;
    })

    return (
        <Page id={2}>
            <div className='amenities-map'>
                <div className='title'>
                    Area Amenities Map
                </div>
                <div className='container'>
                    <div className={"swipe-alert " + alertHidden} onClick={() => setSwipeHidden(!swipeHidden)} onTouchStart={() => setSwipeHidden(!swipeHidden)} >
                        <img className='swipe' src={Swipe} alt={''} />
                        <p className='swipe'>SWIPE TO VIEW MORE</p>
                    </div>
                    <div ref={mapRef} className='map'>
                        <img src={Amenities} alt='' height={1000} />
                        <div className='left'>
                            <img className='nav-arrow' src={Arrow} alt='' height={20}></img>
                        </div>
                        <div className='right' >
                            <img className='nav-arrow' src={Arrow} alt='' height={20}></img>
                        </div>
                    </div>
                    <div className='legend'>
                        <img src={Legend} alt='' />
                    </div>
                </div>
            </div>
        </Page>
    )
}

export default Map