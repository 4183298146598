import * as THREE from 'three'
import React, { Suspense } from 'react'
import { Canvas } from '@react-three/fiber'
import { PerspectiveCamera, OrbitControls, Loader, Environment } from '@react-three/drei'
import { EffectComposer, Vignette, SSAO } from '@react-three/postprocessing'
import { BlendFunction } from 'postprocessing'

import GroundModel from './model/Ground'
import MassingModelTwo from './model/Massing_2'
import PlantersModel from './model/Planters'
import ParkModel from './model/Park'
import RoadModel from './model/Road-2'
import RoadTwoModel from './model/Road-v2'
import RoadMarkingsModel from './model/RoadMarkings'
import TowerModel from './model/tower'
import TowerSideWalk from './model/tower_sidewalk'
import PoolModel from './model/pool'
import SuitesModel from './model/Suites'
import Landscaping from './model/Landscaping'
import FootprintModel from './model/logofootprint'
import Phase1Model from './model/Phase1'

//skybox import
import nx from './assets/skybox/2/nx.png'
import ny from './assets/skybox/2/ny.png'
import nz from './assets/skybox/2/nz.png'
import px from './assets/skybox/2/px.png'
import py from './assets/skybox/2/py.png'
import pz from './assets/skybox/2/pz.png'

const shadowCameraValue = 512

function Viewer() {
  return (
    <>
      <Canvas className='viewer' colorManagement shadows
        onCreated={(state) => {
          state.gl.toneMapping = THREE.NoToneMapping
        }}

        gl={{ antialias: true }}
      >
        <PerspectiveCamera makeDefault position={[-200, 50, 0]} fov={74} near={1} />
        <OrbitControls
          enablePan={false}
          dampingFactor={0.1}
          autoRotate={true}  // change
          autoRotateSpeed={0.5}
          rotateSpeed={0.5}
          target={[-130, 50, 75]}
          minDistance={50}
          maxDistance={150}
          maxPolarAngle={Math.PI / 1.7}
        />
        <directionalLight
          position={[-1, 2, -1]}
          intensity={0.6}
          castShadow
          shadow-mapSize-width={1024}
          shadow-mapSize-height={1024}
          shadow-camera-near={0}
          shadow-camera-far={512}
          shadow-camera-left={-shadowCameraValue}
          shadow-camera-right={shadowCameraValue}
          shadow-camera-top={shadowCameraValue}
          shadow-camera-bottom={-shadowCameraValue}
          shadow-bias={-0.005}
        />
        <Suspense fallback={null}>
          <EffectComposer>
            <Vignette darkness={0.1} />
            <SSAO
              blendFunction={BlendFunction.MULTIPLY}
              samples={32}
              radius={2.5}
              intensity={80}
            />
          </EffectComposer>
          <Environment
            background={true}
            files={[px, nx, py, ny, pz, nz]}
            preset={null}
            scene={undefined}
          />
          <GroundModel />
          <MassingModelTwo />
          <PlantersModel />
          <ParkModel />
          <RoadModel />
          <RoadTwoModel />
          <RoadMarkingsModel />
          <TowerModel />
          <TowerSideWalk />
          <PoolModel />
          <SuitesModel />
          <Landscaping />
          <FootprintModel />
          <Phase1Model />
        </Suspense>
      </Canvas>
      <Loader />
    </>
  );
}

export default Viewer;
