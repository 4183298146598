/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export default function Model({ ...props }) {
  const group = useRef()
  const { nodes, materials } = useGLTF('charisma/phase1/phase1.gltf')
  return (
    <group ref={group} {...props} dispose={null}>
      <mesh castShadow receiveShadow geometry={nodes.Grass.geometry} material={materials.Grassssss} scale={10} />
      <mesh castShadow receiveShadow geometry={nodes.Road.geometry} material={materials['Pool Flooring']} scale={10} />
      <mesh castShadow receiveShadow geometry={nodes.Pavement.geometry} material={nodes.Pavement.material} scale={10} />
      <mesh castShadow receiveShadow geometry={nodes.Seats.geometry} material={materials['Material #711']} scale={10} />
      <mesh castShadow receiveShadow geometry={nodes.Curb.geometry} material={materials['Conc Curb']} scale={10} />
      <mesh castShadow receiveShadow geometry={nodes.Floor.geometry} material={nodes.Floor.material} scale={10} />
    </group>
  )
}

useGLTF.preload('charisma/phase1/phase1.gltf')
