/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export default function Model({ ...props }) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/charisma/pool/pool.gltf')
  return (
    <group ref={group} {...props} dispose={null}>
      <mesh 
      geometry={nodes.Pool_Water.geometry} 
      material={materials.Glass_Building} 
      material-transparent
      material-opacity={0.9}
      material-roughness={0.8}
      material-metalness={1}
      material-color={"#a5c7d6"}
      />
    </group>
  )
}

useGLTF.preload('/charisma/pool/pool.gltf')
