import React, { useState } from 'react'
import Page from './Page'
import Thumb from '../Gallery/Thumb'
import Renders from '../../stores/RenderingStore'
import ScrollView from '../Gallery/ScrollView'

function Renderings() {
    const renders = Renders.exterior
    const [scrollActive, setScrollActive] = useState(false)
    const [scrollRender, setScrollRender] = useState('')
    function showScroll(e) {
        e.preventDefault();
        setScrollActive(true)
    }

    function hideScroll(e) {
        e.preventDefault();
        setScrollActive(false)
    }

    return (
        <Page id={5}>
            <ScrollView active={scrollActive} hideScroll={hideScroll} render={scrollRender} />
            <div className='renderings'>
                <div className='title'>
                    Exterior Renderings
                </div>
                <div className='container'>
                    {renders.map((item, index) => {
                        return <Thumb key={index} render={item}
                            showScroll={(e) => {
                                showScroll(e)
                                setScrollRender(item)
                            }}
                            hideScroll={hideScroll}
                        />
                    })}
                </div>
            </div>
        </Page>
    )
}

export default Renderings