/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import useFloorplanStore from '../stores/FloorplanStore'

export default function Model(props) {
    const group = useRef()
    const { nodes, materials } = useGLTF('/charisma/suites/suites.gltf')
    const currentType = useFloorplanStore(state => state.suiteName)
    const active = useFloorplanStore(state => state.active)

    const activeColor = 0xc79a6e;
    const defaultColor = 0xffffff;
    const suiteOpacity = 0.99

    return (
        <group ref={group} {...props} dispose={null} visible={active}>
            <mesh geometry={nodes.A524_F08.geometry} material={nodes.A524_F08.material}
                material-color={(currentType === 'A524') ? activeColor : defaultColor}
                material-transparent
                material-opacity={suiteOpacity}
                material-visible={(currentType === 'A524')}
            />
            <mesh geometry={nodes.A524_F09.geometry} material={nodes.A524_F09.material} />
            <mesh geometry={nodes.A524_F10.geometry} material={nodes.A524_F10.material} />
            <mesh geometry={nodes.A524_F11.geometry} material={nodes.A524_F11.material} />
            <mesh geometry={nodes.A524_F12.geometry} material={nodes.A524_F12.material} />
            <mesh geometry={nodes.A524_F14.geometry} material={nodes.A524_F14.material} />
            <mesh geometry={nodes.A524_F15.geometry} material={nodes.A524_F15.material} />
            <mesh geometry={nodes.A524_F16.geometry} material={nodes.A524_F16.material} />
            <mesh geometry={nodes.A524_F17.geometry} material={nodes.A524_F17.material} />
            <mesh geometry={nodes.A524_F18.geometry} material={nodes.A524_F18.material} />
            <mesh geometry={nodes.A524_F19.geometry} material={nodes.A524_F19.material} />
            <mesh geometry={nodes.A524_F20.geometry} material={nodes.A524_F20.material} />
            <mesh geometry={nodes.A524_F21.geometry} material={nodes.A524_F21.material} />
            <mesh geometry={nodes.A524_F22.geometry} material={nodes.A524_F22.material} />
            <mesh geometry={nodes.A524_F23.geometry} material={nodes.A524_F23.material} />
            <mesh geometry={nodes.A524_F24.geometry} material={nodes.A524_F24.material} />
            <mesh geometry={nodes.A524_F25.geometry} material={nodes.A524_F25.material} />
            <mesh geometry={nodes.A524_F26.geometry} material={nodes.A524_F26.material}
                material-color={(currentType === 'PH-A524') ? activeColor : defaultColor}
                material-transparent
                material-opacity={suiteOpacity}
                material-visible={(currentType === 'PH-A524')}
            />

            <mesh geometry={nodes.A527_F08.geometry} material={nodes.A527_F08.material}
                material-color={(currentType === 'A527') ? activeColor : defaultColor}
                material-transparent
                material-opacity={suiteOpacity}
                material-visible={(currentType === 'A527')}
                material-emissive={(currentType === 'A527') ? activeColor : defaultColor}
            />
            <mesh geometry={nodes.A527_F09.geometry} material={nodes.A527_F09.material} />
            <mesh geometry={nodes.A527_F10.geometry} material={nodes.A527_F10.material} />
            <mesh geometry={nodes.A527_F11.geometry} material={nodes.A527_F11.material} />
            <mesh geometry={nodes.A527_F12.geometry} material={nodes.A527_F12.material} />
            <mesh geometry={nodes.A527_F14.geometry} material={nodes.A527_F14.material} />
            <mesh geometry={nodes.A527_F15.geometry} material={nodes.A527_F15.material} />
            <mesh geometry={nodes.A527_F16.geometry} material={nodes.A527_F16.material} />
            <mesh geometry={nodes.A527_F17.geometry} material={nodes.A527_F17.material} />
            <mesh geometry={nodes.A527_F18.geometry} material={nodes.A527_F18.material} />
            <mesh geometry={nodes.A527_F19.geometry} material={nodes.A527_F19.material} />
            <mesh geometry={nodes.A527_F20.geometry} material={nodes.A527_F20.material} />
            <mesh geometry={nodes.A527_F21.geometry} material={nodes.A527_F21.material} />
            <mesh geometry={nodes.A527_F22.geometry} material={nodes.A527_F22.material} />
            <mesh geometry={nodes.A527_F23.geometry} material={nodes.A527_F23.material} />
            <mesh geometry={nodes.A527_F24.geometry} material={nodes.A527_F24.material} />
            <mesh geometry={nodes.A527_F25.geometry} material={nodes.A527_F25.material} />
            <mesh geometry={nodes.A527_F26.geometry} material={nodes.A527_F26.material}
                material-color={(currentType === 'PH-A527') ? activeColor : defaultColor}
                material-transparent
                material-opacity={suiteOpacity}
                material-visible={(currentType === 'PH-A527')}
            />

            <mesh geometry={nodes.A538_F08.geometry} material={nodes.A538_F08.material}
                material-color={(currentType === 'A538') ? activeColor : defaultColor}
                material-transparent
                material-opacity={suiteOpacity}
                material-visible={(currentType === 'A538')}
            />
            <mesh geometry={nodes.A538_F09.geometry} material={nodes.A538_F09.material} />
            <mesh geometry={nodes.A538_F10.geometry} material={nodes.A538_F10.material} />
            <mesh geometry={nodes.A538_F11.geometry} material={nodes.A538_F11.material} />
            <mesh geometry={nodes.A538_F12.geometry} material={nodes.A538_F12.material} />
            <mesh geometry={nodes.A538_F14.geometry} material={nodes.A538_F14.material} />
            <mesh geometry={nodes.A538_F15.geometry} material={nodes.A538_F15.material} />
            <mesh geometry={nodes.A538_F16.geometry} material={nodes.A538_F16.material} />
            <mesh geometry={nodes.A538_F17.geometry} material={nodes.A538_F17.material} />
            <mesh geometry={nodes.A538_F18.geometry} material={nodes.A538_F18.material} />
            <mesh geometry={nodes.A538_F19.geometry} material={nodes.A538_F19.material} />
            <mesh geometry={nodes.A538_F20.geometry} material={nodes.A538_F20.material} />
            <mesh geometry={nodes.A538_F21.geometry} material={nodes.A538_F21.material} />
            <mesh geometry={nodes.A538_F22.geometry} material={nodes.A538_F22.material} />
            <mesh geometry={nodes.A538_F23.geometry} material={nodes.A538_F23.material} />
            <mesh geometry={nodes.A538_F24.geometry} material={nodes.A538_F24.material} />
            <mesh geometry={nodes.A538_F25.geometry} material={nodes.A538_F25.material} />
            <mesh geometry={nodes.A538_F26.geometry} material={nodes.A538_F26.material}
                material-color={(currentType === 'PH-A538') ? activeColor : defaultColor}
                material-transparent
                material-opacity={suiteOpacity}
                material-visible={(currentType === 'PH-A538')}
            />

            <mesh geometry={nodes.A596_F02.geometry} material={nodes.A596_F02.material}
                material-color={(currentType === 'A596') ? activeColor : defaultColor}
                material-transparent
                material-opacity={suiteOpacity}
                material-visible={(currentType === 'A596')}
            />
            <mesh geometry={nodes.A596_F03.geometry} material={nodes.A596_F03.material} />
            <mesh geometry={nodes.A596_F04.geometry} material={nodes.A596_F04.material} />
            <mesh geometry={nodes.A596_F05.geometry} material={nodes.A596_F05.material} />
            <mesh geometry={nodes.A596_F06.geometry} material={nodes.A596_F06.material} />

            <mesh geometry={nodes.A635_F04.geometry} material={nodes.A635_F04.material}
                material-color={(currentType === 'A635') ? activeColor : defaultColor}
                material-transparent
                material-opacity={suiteOpacity}
                material-visible={(currentType === 'A635')}
            />
            <mesh geometry={nodes.A635_F05.geometry} material={nodes.A635_F05.material} />

            <mesh geometry={nodes.A673_F3.geometry} material={materials.Mat_A673}
                material-color={(currentType === 'A673') ? activeColor : defaultColor}
                material-transparent
                material-opacity={suiteOpacity}
                material-visible={(currentType === 'A673')}
            />

            <mesh geometry={nodes.B650_F02.geometry} material={nodes.B650_F02.material}
                material-color={(currentType === 'B650') ? activeColor : defaultColor}
                material-transparent
                material-opacity={suiteOpacity}
                material-visible={(currentType === 'B650')}
            />
            <mesh geometry={nodes.B650_F03.geometry} material={nodes.B650_F03.material} />
            <mesh geometry={nodes.B650_F04.geometry} material={nodes.B650_F04.material} />
            <mesh geometry={nodes.B650_F05.geometry} material={nodes.B650_F05.material} />
            <mesh geometry={nodes.B650_F06.geometry} material={nodes.B650_F06.material} />

            <mesh geometry={nodes.B651_F02.geometry} material={nodes.B651_F02.material}
                material-color={(currentType === 'B651') ? activeColor : defaultColor}
                material-transparent
                material-opacity={suiteOpacity}
                material-visible={(currentType === 'B651')}
            />
            <mesh geometry={nodes.B651_F03.geometry} material={nodes.B651_F03.material} />
            <mesh geometry={nodes.B651_F04.geometry} material={nodes.B651_F04.material} />
            <mesh geometry={nodes.B651_F05.geometry} material={nodes.B651_F05.material} />
            <mesh geometry={nodes.B651_F06.geometry} material={nodes.B651_F06.material} />

            <mesh geometry={nodes.B663_F03.geometry} material={nodes.B663_F03.material}
                material-color={(currentType === 'B663') ? activeColor : defaultColor}
                material-transparent
                material-opacity={suiteOpacity}
                material-visible={(currentType === 'B663')}
            />
            <mesh geometry={nodes.B663_F04.geometry} material={nodes.B663_F04.material} />
            <mesh geometry={nodes.B663_F05.geometry} material={nodes.B663_F05.material} />
            <mesh geometry={nodes.B663_F06.geometry} material={nodes.B663_F06.material} />

            <mesh geometry={nodes.B664_F02.geometry} material={nodes.B664_F02.material}
                material-color={(currentType === 'B664') ? activeColor : defaultColor}
                material-transparent
                material-opacity={suiteOpacity}
                material-visible={(currentType === 'B664')}
            />
            <mesh geometry={nodes.B664_F03.geometry} material={nodes.B664_F03.material} />
            <mesh geometry={nodes.B664_F04.geometry} material={nodes.B664_F04.material} />
            <mesh geometry={nodes.B664_F05.geometry} material={nodes.B664_F05.material} />
            <mesh geometry={nodes.B664_F06.geometry} material={nodes.B664_F06.material} />

            <mesh geometry={nodes.B669_F02.geometry} material={nodes.B669_F02.material}
                material-color={(currentType === 'B669') ? activeColor : defaultColor}
                material-transparent
                material-opacity={suiteOpacity}
                material-visible={(currentType === 'B669')}
            />
            <mesh geometry={nodes.B669_F04.geometry} material={nodes.B669_F04.material} />
            <mesh geometry={nodes.B669_F05.geometry} material={nodes.B669_F05.material} />

            <mesh geometry={nodes.B671_F03.geometry} material={nodes.B671_F03.material}
                material-color={(currentType === 'B671') ? activeColor : defaultColor}
                material-transparent
                material-opacity={suiteOpacity}
                material-visible={(currentType === 'B671')}
            />
            <mesh geometry={nodes.B671_F04.geometry} material={nodes.B671_F04.material} />
            <mesh geometry={nodes.B671_F05.geometry} material={nodes.B671_F05.material} />
            <mesh geometry={nodes.B671_F06.geometry} material={nodes.B671_F06.material} />

            <mesh geometry={nodes.B695_F08.geometry} material={nodes.B695_F08.material}
                material-color={(currentType === 'B695') ? activeColor : defaultColor}
                material-transparent
                material-opacity={suiteOpacity}
                material-visible={(currentType === 'B695')}
            />
            <mesh geometry={nodes.B695_F09.geometry} material={nodes.B695_F09.material} />
            <mesh geometry={nodes.B695_F10.geometry} material={nodes.B695_F10.material} />
            <mesh geometry={nodes.B695_F11.geometry} material={nodes.B695_F11.material} />
            <mesh geometry={nodes.B695_F12.geometry} material={nodes.B695_F12.material} />
            <mesh geometry={nodes.B695_F14.geometry} material={nodes.B695_F14.material} />
            <mesh geometry={nodes.B695_F15.geometry} material={nodes.B695_F15.material} />
            <mesh geometry={nodes.B695_F16.geometry} material={nodes.B695_F16.material} />
            <mesh geometry={nodes.B695_F17.geometry} material={nodes.B695_F17.material} />
            <mesh geometry={nodes.B695_F18.geometry} material={nodes.B695_F18.material} />
            <mesh geometry={nodes.B695_F19.geometry} material={nodes.B695_F19.material} />
            <mesh geometry={nodes.B695_F20.geometry} material={nodes.B695_F20.material} />
            <mesh geometry={nodes.B695_F21.geometry} material={nodes.B695_F21.material} />
            <mesh geometry={nodes.B695_F22.geometry} material={nodes.B695_F22.material} />
            <mesh geometry={nodes.B695_F23.geometry} material={nodes.B695_F23.material} />
            <mesh geometry={nodes.B695_F24.geometry} material={nodes.B695_F24.material} />
            <mesh geometry={nodes.B695_F25.geometry} material={nodes.B695_F25.material} />
            <mesh geometry={nodes.B695_F26.geometry} material={nodes.B695_F26.material}
                material-color={(currentType === 'PH-B695') ? activeColor : defaultColor}
                material-transparent
                material-opacity={suiteOpacity}
                material-visible={(currentType === 'PH-B695')}
            />

            <mesh geometry={nodes.B725_F08.geometry} material={nodes.B725_F08.material}
                material-color={(currentType === 'B725') ? activeColor : defaultColor}
                material-transparent
                material-opacity={suiteOpacity}
                material-visible={(currentType === 'B725')}
            />
            <mesh geometry={nodes.B725_F09.geometry} material={nodes.B725_F09.material} />
            <mesh geometry={nodes.B725_F10.geometry} material={nodes.B725_F10.material} />
            <mesh geometry={nodes.B725_F11.geometry} material={nodes.B725_F11.material} />
            <mesh geometry={nodes.B725_F12.geometry} material={nodes.B725_F12.material} />
            <mesh geometry={nodes.B725_F14.geometry} material={nodes.B725_F14.material} />
            <mesh geometry={nodes.B725_F15.geometry} material={nodes.B725_F15.material} />
            <mesh geometry={nodes.B725_F16.geometry} material={nodes.B725_F16.material} />
            <mesh geometry={nodes.B725_F17.geometry} material={nodes.B725_F17.material} />
            <mesh geometry={nodes.B725_F18.geometry} material={nodes.B725_F18.material} />
            <mesh geometry={nodes.B725_F19.geometry} material={nodes.B725_F19.material} />
            <mesh geometry={nodes.B725_F20.geometry} material={nodes.B725_F20.material} />
            <mesh geometry={nodes.B725_F21.geometry} material={nodes.B725_F21.material} />
            <mesh geometry={nodes.B725_F22.geometry} material={nodes.B725_F22.material} />
            <mesh geometry={nodes.B725_F23.geometry} material={nodes.B725_F23.material} />
            <mesh geometry={nodes.B725_F24.geometry} material={nodes.B725_F24.material} />
            <mesh geometry={nodes.B725_F25.geometry} material={nodes.B725_F25.material} />
            <mesh geometry={nodes.B725_F26.geometry} material={nodes.B725_F26.material}
                material-color={(currentType === 'PH-B725') ? activeColor : defaultColor}
                material-transparent
                material-opacity={suiteOpacity}
                material-visible={(currentType === 'PH-B725')}
            />

            <mesh geometry={nodes.B757_F08.geometry} material={nodes.B757_F08.material}
                material-color={(currentType === 'B757') ? activeColor : defaultColor}
                material-transparent
                material-opacity={suiteOpacity}
                material-visible={(currentType === 'B757')}
            />
            <mesh geometry={nodes.B757_F09.geometry} material={nodes.B757_F09.material} />
            <mesh geometry={nodes.B757_F10.geometry} material={nodes.B757_F10.material} />
            <mesh geometry={nodes.B757_F11.geometry} material={nodes.B757_F11.material} />
            <mesh geometry={nodes.B757_F12.geometry} material={nodes.B757_F12.material} />
            <mesh geometry={nodes.B757_F14.geometry} material={nodes.B757_F14.material} />
            <mesh geometry={nodes.B757_F15.geometry} material={nodes.B757_F15.material} />
            <mesh geometry={nodes.B757_F16.geometry} material={nodes.B757_F16.material} />
            <mesh geometry={nodes.B757_F17.geometry} material={nodes.B757_F17.material} />
            <mesh geometry={nodes.B757_F18.geometry} material={nodes.B757_F18.material} />
            <mesh geometry={nodes.B757_F19.geometry} material={nodes.B757_F19.material} />
            <mesh geometry={nodes.B757_F20.geometry} material={nodes.B757_F20.material} />
            <mesh geometry={nodes.B757_F21.geometry} material={nodes.B757_F21.material} />
            <mesh geometry={nodes.B757_F22.geometry} material={nodes.B757_F22.material} />
            <mesh geometry={nodes.B757_F23.geometry} material={nodes.B757_F23.material} />
            <mesh geometry={nodes.B757_F24.geometry} material={nodes.B757_F24.material} />
            <mesh geometry={nodes.B757_F25.geometry} material={nodes.B757_F25.material} />
            <mesh geometry={nodes.B757_F26.geometry} material={nodes.B757_F26.material}
                material-color={(currentType === 'PH-B757') ? activeColor : defaultColor}
                material-transparent
                material-opacity={suiteOpacity}
                material-visible={(currentType === 'PH-B757')}
            />

            <mesh geometry={nodes.B777_F03.geometry} material={nodes.B777_F03.material}
                material-color={(currentType === 'B777') ? activeColor : defaultColor}
                material-transparent
                material-opacity={suiteOpacity}
                material-visible={(currentType === 'B777')}
            />
            <mesh geometry={nodes.B777_F04.geometry} material={nodes.B777_F04.material} />
            <mesh geometry={nodes.B777_F05.geometry} material={nodes.B777_F05.material} />
            <mesh geometry={nodes.B777_F06.geometry} material={nodes.B777_F06.material} />

            <mesh geometry={nodes.B783_F02.geometry} material={nodes.B783_F02.material}
                material-color={(currentType === 'B783') ? activeColor : defaultColor}
                material-transparent
                material-opacity={suiteOpacity}
                material-visible={(currentType === 'B783')}
            />
            <mesh geometry={nodes.B783_F03.geometry} material={nodes.B783_F03.material} />
            <mesh geometry={nodes.B783_F04.geometry} material={nodes.B783_F04.material} />
            <mesh geometry={nodes.B783_F05.geometry} material={nodes.B783_F05.material} />
            <mesh geometry={nodes.B783_F06.geometry} material={nodes.B783_F06.material} />

            <mesh geometry={nodes.B786_F02.geometry} material={nodes.B786_F02.material}
                material-color={(currentType === 'B786') ? activeColor : defaultColor}
                material-transparent
                material-opacity={suiteOpacity}
                material-visible={(currentType === 'B786')}
            />
            <mesh geometry={nodes.B786_F03.geometry} material={nodes.B786_F03.material} />
            <mesh geometry={nodes.B786_F04.geometry} material={nodes.B786_F04.material} />
            <mesh geometry={nodes.B786_F05.geometry} material={nodes.B786_F05.material} />
            <mesh geometry={nodes.B786_F06.geometry} material={nodes.B786_F06.material} />

            <mesh geometry={nodes.B790_F02.geometry} material={nodes.B790_F02.material}
                material-color={(currentType === 'B790') ? activeColor : defaultColor}
                material-transparent
                material-opacity={suiteOpacity}
                material-visible={(currentType === 'B790')}
            />
            <mesh geometry={nodes.B790_F03.geometry} material={nodes.B790_F03.material} />
            <mesh geometry={nodes.B790_F04.geometry} material={nodes.B790_F04.material} />
            <mesh geometry={nodes.B790_F05.geometry} material={nodes.B790_F05.material} />
            <mesh geometry={nodes.B790_F06.geometry} material={nodes.B790_F06.material} />

            <mesh geometry={nodes.B810_F02.geometry} material={nodes.B810_F02.material}
                material-color={(currentType === 'B810') ? activeColor : defaultColor}
                material-transparent
                material-opacity={suiteOpacity}
                material-visible={(currentType === 'B810')}
            />
            <mesh geometry={nodes.B810_F03.geometry} material={nodes.B810_F03.material} />
            <mesh geometry={nodes.B810_F04.geometry} material={nodes.B810_F04.material} />
            <mesh geometry={nodes.B810_F05.geometry} material={nodes.B810_F05.material} />
            <mesh geometry={nodes.B810_F06.geometry} material={nodes.B810_F06.material} />

            <mesh geometry={nodes.C888_F03.geometry} material={nodes.C888_F03.material}
                material-color={(currentType === 'C888') ? activeColor : defaultColor}
                material-transparent
                material-opacity={suiteOpacity}
                material-visible={(currentType === 'C888')}
            />
            <mesh geometry={nodes.C888_F04.geometry} material={nodes.C888_F04.material} />
            <mesh geometry={nodes.C888_F05.geometry} material={nodes.C888_F05.material} />

            <mesh geometry={nodes.C891S_F08.geometry} material={nodes.C891S_F08.material}
                material-color={(currentType === 'C891') ? activeColor : defaultColor}
                material-transparent
                material-opacity={suiteOpacity}
                material-visible={(currentType === 'C891')}
            />
            <mesh geometry={nodes.C891S_F09.geometry} material={nodes.C891S_F09.material} />
            <mesh geometry={nodes.C891S_F10.geometry} material={nodes.C891S_F10.material} />
            <mesh geometry={nodes.C891S_F11.geometry} material={nodes.C891S_F11.material} />
            <mesh geometry={nodes.C891S_F12.geometry} material={nodes.C891S_F12.material} />
            <mesh geometry={nodes.C891S_F14.geometry} material={nodes.C891S_F14.material} />
            <mesh geometry={nodes.C891S_F15.geometry} material={nodes.C891S_F15.material} />
            <mesh geometry={nodes.C891S_F16.geometry} material={nodes.C891S_F16.material} />
            <mesh geometry={nodes.C891S_F17.geometry} material={nodes.C891S_F17.material} />
            <mesh geometry={nodes.C891S_F18.geometry} material={nodes.C891S_F18.material} />
            <mesh geometry={nodes.C891S_F19.geometry} material={nodes.C891S_F19.material} />
            <mesh geometry={nodes.C891S_F20.geometry} material={nodes.C891S_F20.material} />
            <mesh geometry={nodes.C891S_F21.geometry} material={nodes.C891S_F21.material} />
            <mesh geometry={nodes.C891S_F22.geometry} material={nodes.C891S_F22.material} />
            <mesh geometry={nodes.C891S_F23.geometry} material={nodes.C891S_F23.material} />
            <mesh geometry={nodes.C891S_F24.geometry} material={nodes.C891S_F24.material} />
            <mesh geometry={nodes.C891S_F25.geometry} material={nodes.C891S_F25.material} />
            <mesh geometry={nodes.C891S_F26.geometry} material={nodes.C891S_F26.material}
                material-color={(currentType === 'PH-C891') ? activeColor : defaultColor}
                material-transparent
                material-opacity={suiteOpacity}
                material-visible={(currentType === 'PH-C891')}
            />

            <mesh geometry={nodes.C891N_F08.geometry} material={nodes.C891N_F08.material} />
            <mesh geometry={nodes.C891N_F09.geometry} material={nodes.C891N_F09.material} />
            <mesh geometry={nodes.C891N_F10.geometry} material={nodes.C891N_F10.material} />
            <mesh geometry={nodes.C891N_F11.geometry} material={nodes.C891N_F11.material} />
            <mesh geometry={nodes.C891N_F12.geometry} material={nodes.C891N_F12.material} />
            <mesh geometry={nodes.C891N_F14.geometry} material={nodes.C891N_F14.material} />
            <mesh geometry={nodes.C891N_F15.geometry} material={nodes.C891N_F15.material} />
            <mesh geometry={nodes.C891N_F16.geometry} material={nodes.C891N_F16.material} />
            <mesh geometry={nodes.C891N_F17.geometry} material={nodes.C891N_F17.material} />
            <mesh geometry={nodes.C891N_F18.geometry} material={nodes.C891N_F18.material} />
            <mesh geometry={nodes.C891N_F19.geometry} material={nodes.C891N_F19.material} />
            <mesh geometry={nodes.C891N_F20.geometry} material={nodes.C891N_F20.material} />
            <mesh geometry={nodes.C891N_F21.geometry} material={nodes.C891N_F21.material} />
            <mesh geometry={nodes.C891N_F22.geometry} material={nodes.C891N_F22.material} />
            <mesh geometry={nodes.C891N_F23.geometry} material={nodes.C891N_F23.material} />
            <mesh geometry={nodes.C891N_F24.geometry} material={nodes.C891N_F24.material} />
            <mesh geometry={nodes.C891N_F25.geometry} material={nodes.C891N_F25.material} />
            <mesh geometry={nodes.C891N_F26.geometry} material={nodes.C891N_F26.material} />

            <mesh geometry={nodes.C896_F02.geometry} material={nodes.C896_F02.material}
                material-color={(currentType === 'C896') ? activeColor : defaultColor}
                material-transparent
                material-opacity={suiteOpacity}
                material-visible={(currentType === 'C896')}
            />
            <mesh geometry={nodes.C896_F03.geometry} material={nodes.C896_F03.material} />
            <mesh geometry={nodes.C896_F04.geometry} material={nodes.C896_F04.material} />
            <mesh geometry={nodes.C896_F05.geometry} material={nodes.C896_F05.material} />
            <mesh geometry={nodes.C896_F06.geometry} material={nodes.C896_F06.material} />

            <mesh geometry={nodes.C902_F02.geometry} material={nodes.C902_F02.material}
                material-color={(currentType === 'C902') ? activeColor : defaultColor}
                material-transparent
                material-opacity={suiteOpacity}
                material-visible={(currentType === 'C902')}
            />
            <mesh geometry={nodes.C902_F03.geometry} material={nodes.C902_F03.material} />
            <mesh geometry={nodes.C902_F04.geometry} material={nodes.C902_F04.material} />
            <mesh geometry={nodes.C902_F05.geometry} material={nodes.C902_F05.material} />
            <mesh geometry={nodes.C902_F06.geometry} material={nodes.C902_F06.material} />

            <mesh geometry={nodes.B750_F02.geometry} material={nodes.B750_F02.material}
                material-color={(currentType === 'B750') ? activeColor : defaultColor}
                material-transparent
                material-opacity={suiteOpacity}
                material-visible={(currentType === 'B750')}
            />
            <mesh geometry={nodes.B750_F03.geometry} material={nodes.B750_F03.material} />
            <mesh geometry={nodes.B750_F04.geometry} material={nodes.B750_F04.material} />
            <mesh geometry={nodes.B750_F05.geometry} material={nodes.B750_F05.material} />
            <mesh geometry={nodes.B750_F06.geometry} material={nodes.B750_F06.material} />

            <mesh geometry={nodes.C970_F18.geometry} material={nodes.C970_F18.material}
                material-color={(currentType === 'C970') ? activeColor : defaultColor}
                material-transparent
                material-opacity={suiteOpacity}
                material-visible={(currentType === 'C970')}
            />
            <mesh geometry={nodes.C970_F19.geometry} material={nodes.C970_F19.material} />
            <mesh geometry={nodes.C970_F20.geometry} material={nodes.C970_F20.material} />
            <mesh geometry={nodes.C970_F21.geometry} material={nodes.C970_F21.material} />
            <mesh geometry={nodes.C970_F22.geometry} material={nodes.C970_F22.material} />
            <mesh geometry={nodes.C970_F23.geometry} material={nodes.C970_F23.material} />
            <mesh geometry={nodes.C970_F24.geometry} material={nodes.C970_F24.material} />
            <mesh geometry={nodes.C970_F25.geometry} material={nodes.C970_F25.material} />
            <mesh geometry={nodes.C970_F26.geometry} material={nodes.C970_F26.material}
                material-color={(currentType === 'PH-C970') ? activeColor : defaultColor}
                material-transparent
                material-opacity={suiteOpacity}
                material-visible={(currentType === 'PH-C970')}
            />

            <mesh geometry={nodes.A586_F18.geometry} material={nodes.A586_F18.material}
                material-color={(currentType === 'A586') ? activeColor : defaultColor}
                material-transparent
                material-opacity={suiteOpacity}
                material-visible={(currentType === 'A586')}
            />
            <mesh geometry={nodes.A586_F19.geometry} material={nodes.A586_F19.material} />
            <mesh geometry={nodes.A586_F20.geometry} material={nodes.A586_F20.material} />
            <mesh geometry={nodes.A586_F21.geometry} material={nodes.A586_F21.material} />
            <mesh geometry={nodes.A586_F22.geometry} material={nodes.A586_F22.material} />
            <mesh geometry={nodes.A586_F23.geometry} material={nodes.A586_F23.material} />
            <mesh geometry={nodes.A586_F24.geometry} material={nodes.A586_F24.material} />
            <mesh geometry={nodes.A586_F25.geometry} material={nodes.A586_F25.material} />
            <mesh geometry={nodes.A586_F09.geometry} material={nodes.A586_F09.material} />
            <mesh geometry={nodes.A586_F10.geometry} material={nodes.A586_F10.material} />
            <mesh geometry={nodes.A586_F11.geometry} material={nodes.A586_F11.material} />
            <mesh geometry={nodes.A586_F12.geometry} material={nodes.A586_F12.material} />
            <mesh geometry={nodes.A586_F14.geometry} material={nodes.A586_F14.material} />
            <mesh geometry={nodes.A586_F15.geometry} material={nodes.A586_F15.material} />
            <mesh geometry={nodes.A586_F16.geometry} material={nodes.A586_F16.material} />
            <mesh geometry={nodes.A586_F17.geometry} material={nodes.A586_F17.material} />
            <mesh geometry={nodes.A586_F08.geometry} material={nodes.A586_F08.material} />
            <mesh geometry={nodes.A586_F26.geometry} material={nodes.A586_F26.material}
                material-color={(currentType === 'PH-A586') ? activeColor : defaultColor}
                material-transparent
                material-opacity={suiteOpacity}
                material-visible={(currentType === 'PH-A586')}
            />

            <mesh geometry={nodes.C970BF_F08.geometry} material={nodes.C970BF_F08.material}
                material-color={(currentType === 'C970BF') ? activeColor : defaultColor}
                material-transparent
                material-opacity={suiteOpacity}
                material-visible={(currentType === 'C970BF')}
            />
            <mesh geometry={nodes.C970BF_F09.geometry} material={nodes.C970BF_F09.material} />
            <mesh geometry={nodes.C970BF_F10.geometry} material={nodes.C970BF_F10.material} />
            <mesh geometry={nodes.C970BF_F11.geometry} material={nodes.C970BF_F11.material} />
            <mesh geometry={nodes.C970BF_F12.geometry} material={nodes.C970BF_F12.material} />
            <mesh geometry={nodes.C970BF_F14.geometry} material={nodes.C970BF_F14.material} />
            <mesh geometry={nodes.C970BF_F15.geometry} material={nodes.C970BF_F15.material} />
            <mesh geometry={nodes.C970BF_F16.geometry} material={nodes.C970BF_F16.material} />
            <mesh geometry={nodes.C970BF_F17.geometry} material={nodes.C970BF_F17.material} />

            <mesh geometry={nodes.D1195S_F06.geometry} material={nodes.D1195S_F06.material}
                material-color={(currentType === 'D1195') ? activeColor : defaultColor}
                material-transparent
                material-opacity={suiteOpacity}
                material-visible={(currentType === 'D1195')}
            />
            <mesh geometry={nodes.D1195N_F06.geometry} material={nodes.D1195N_F06.material} />

            <mesh geometry={nodes.C868_F08.geometry} material={nodes.C868_F08.material}
                material-color={(currentType === 'C868') ? activeColor : defaultColor}
                material-transparent
                material-opacity={suiteOpacity}
                material-visible={(currentType === 'C868')}
            />
            <mesh geometry={nodes.C868_F09.geometry} material={nodes.C868_F09.material} />
            <mesh geometry={nodes.C868_F10.geometry} material={nodes.C868_F10.material} />
            <mesh geometry={nodes.C868_F11.geometry} material={nodes.C868_F11.material} />
            <mesh geometry={nodes.C868_F12.geometry} material={nodes.C868_F12.material} />
            <mesh geometry={nodes.C868_F14.geometry} material={nodes.C868_F14.material} />
            <mesh geometry={nodes.C868_F15.geometry} material={nodes.C868_F15.material} />
            <mesh geometry={nodes.C868_F16.geometry} material={nodes.C868_F16.material} />
            <mesh geometry={nodes.C868_F17.geometry} material={nodes.C868_F17.material} />
            <mesh geometry={nodes.C868_F18.geometry} material={nodes.C868_F18.material} />
            <mesh geometry={nodes.C868_F19.geometry} material={nodes.C868_F19.material} />
            <mesh geometry={nodes.C868_F20.geometry} material={nodes.C868_F20.material} />
            <mesh geometry={nodes.C868_F21.geometry} material={nodes.C868_F21.material} />
            <mesh geometry={nodes.C868_F22.geometry} material={nodes.C868_F22.material} />
            <mesh geometry={nodes.C868_F23.geometry} material={nodes.C868_F23.material} />
            <mesh geometry={nodes.C868_F24.geometry} material={nodes.C868_F24.material} />
            <mesh geometry={nodes.C868_F25.geometry} material={nodes.C868_F25.material} />
            <mesh geometry={nodes.C868_F26.geometry} material={nodes.C868_F26.material}
                material-color={(currentType === 'PH-C868') ? activeColor : defaultColor}
                material-transparent
                material-opacity={suiteOpacity}
                material-visible={(currentType === 'PH-C868')}
            />

            <mesh geometry={nodes.B748_F02.geometry} material={nodes.B748_F02.material}
                material-color={(currentType === 'B748') ? activeColor : defaultColor}
                material-transparent
                material-opacity={suiteOpacity}
                material-visible={(currentType === 'B748')}
            />
            <mesh geometry={nodes.B748_F03.geometry} material={nodes.B748_F03.material} />
            <mesh geometry={nodes.B748_F04.geometry} material={nodes.B748_F04.material} />
            <mesh geometry={nodes.B748_F05.geometry} material={nodes.B748_F05.material} />
            <mesh geometry={nodes.B748_F06.geometry} material={nodes.B748_F06.material} />
        </group>
    )
}

useGLTF.preload('/charisma/suites/suites.gltf')
