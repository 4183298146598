import React from 'react'
import useAppStore from '../../stores/AppStore'

function Page(props) {
    const pageIndex = useAppStore(state => state.pageIndex);
    const active = props.id === pageIndex;
    const fade = active ? 'fade-in' : 'fade-out';
    return (
        <div className={'page ' + fade}>
            {props.children}
        </div>
    )
}

export default Page