import React from 'react'
import useAppStore from '../../stores/AppStore'

function NavButton(props) {
    const pageIndex = useAppStore(state => state.pageIndex)
    const changePage = useAppStore(state => state.changePage)

    const active = props.id === pageIndex ? 'active' : ''
    return (
        <div className={'nav-button'}>
            <a className={active} onClick={() => changePage(props.id)} href="/#">{props.name}</a>
        </div >
    )
}

export default NavButton