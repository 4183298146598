/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export default function Model({ ...props }) {
    const group = useRef()
    const { nodes, materials } = useGLTF('charisma/landscaping/landscaping.gltf')

    const roughness = 1;

    return (
        <group ref={group} {...props} dispose={null}>
            <group scale={1.84}>
                <mesh
                    geometry={nodes.Tree_Cypress_1.geometry}
                    material={materials.Cypress}
                    material-roughness={roughness}
                    receiveShadow
                    castShadow
                />
                <mesh
                    geometry={nodes.Tree_Cypress_2.geometry}
                    material={materials['Mat_Tree_Cypress-Trunk']}
                    material-roughness={roughness}
                    receiveShadow
                    castShadow
                />
            </group>
            <mesh
                geometry={nodes.Tree_Massing_1.geometry}
                material={materials.Tree_Leaves}
                material-roughness={roughness}
                receiveShadow
                castShadow
            />
            <mesh
                geometry={nodes.Tree_Massing_2.geometry}
                material={nodes.Tree_Massing_2.material}
                material-roughness={roughness}
                receiveShadow
                castShadow
            />
            <mesh
                geometry={nodes.Bush_Mass.geometry}
                material={materials.Shrub}
                material-roughness={roughness}
                receiveShadow
                castShadow
            />
            <mesh
                geometry={nodes.Trees_Massing_Red_1.geometry}
                material={materials.Tree_Leaves_Red}
                material-roughness={roughness}
                receiveShadow
                castShadow
            />
            <mesh
                geometry={nodes.Trees_Massing_Red_2.geometry}
                material={nodes.Trees_Massing_Red_2.material}
                material-roughness={roughness}
                receiveShadow
                castShadow
            />
        </group>
    )
}

useGLTF.preload('charisma/landscaping/landscaping.gltf')
