/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export default function Model({ ...props }) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/charisma/tower_sidewalk/tower_sidewalk.gltf')
  return (
    <group ref={group} {...props} dispose={null}>
      <mesh geometry={nodes.Tilebase.geometry} material={materials.Tilesss} receiveShadow/>
      <mesh geometry={nodes.benchframeebase.geometry} material={nodes.benchframeebase.material} receiveShadow/>
      <mesh geometry={nodes.benchtablebase.geometry} material={nodes.benchtablebase.material} receiveShadow/>
      <mesh geometry={nodes.benchlegsbase.geometry} material={nodes.benchlegsbase.material} receiveShadow/>
      <mesh geometry={nodes.benchbase.geometry} material={nodes.benchbase.material} receiveShadow/>
      <mesh geometry={nodes.gratebase.geometry} material={nodes.gratebase.material} receiveShadow/>
      <mesh geometry={nodes.curbbase.geometry} material={materials['Conc Curb']} receiveShadow/>
      <mesh geometry={nodes.Grass001.geometry} material={materials.Grassssss} receiveShadow/>
    </group>
  )
}

useGLTF.preload('/charisma/tower_sidewalk/tower_sidewalk.gltf')
