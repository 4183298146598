import React from 'react'
import Page from '../../Page/'
import BuilderImage from '../../../../assets/images/builder_long.jpg'
import './Builder.scss'

function Builder() {
    return (
        <Page id={6}>
            <div className="builder">
                <div className="scroll">
                <img src={BuilderImage} alt="Builder"></img>
                </div>
            </div>
        </Page>
    )
}

export default Builder