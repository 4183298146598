import React, { useRef, useState } from 'react'
import Page from '../../Page/'
import './Amenities.scss'
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Renders from '../../../../stores/RenderingStore'
import Arrow from '../../../../assets/images/icons/arrow.webp'

import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'

function Amenities() {
    const floorplans = Renders.floorplans;
    const interior = Renders.interior;

    var settings = {
        dots: false,
        arrows: false,
        infinite: true,
        easing: 'ease-in-out',
        speed: 250,
        slidesToShow: 2,
        slidesToScroll: 1,
    }

    const slider1 = useRef()
    const slider2 = useRef()

    const [LightBoxImage, setLightBoxImage] = useState("")
    const [showLightBox, setShowLightBox] = useState(false)
    const LightBox = () => {
        if (showLightBox) {
            return (
                <div className='lightbox'>
                    <div className='image'>
                        <TransformWrapper>
                            <TransformComponent>
                                <img src={LightBoxImage} alt=''></img>
                            </TransformComponent>
                        </TransformWrapper>
                    </div>
                    <p onClick={() => {
                        setShowLightBox(false)
                    }}>close</p>
                </div>
            )
        } else {
            return <></>
        }
    }

    return (
        <Page id={3}>
            <div className='amenities'>
                <div className='container'>
                    <div className='title-container'>
                        <div className='title'>
                            Amenity Floor Plans
                        </div>
                        <div className='arrows'>
                            <img className='left'
                                src={Arrow}
                                onClick={() => {
                                    slider1.current.slickPrev()
                                }}
                                alt=''
                            />
                            <img
                                className='right'
                                src={Arrow}
                                onClick={() => {
                                    slider1.current.slickNext()
                                }}
                                alt=''
                            />
                        </div>
                    </div>
                    <Slider ref={slider => (slider1.current = slider)} {...settings}>
                        {floorplans.map((item) => {
                            return (
                                <div className='slide' key={item}
                                    onClick={() => {
                                        setShowLightBox(true)
                                        setLightBoxImage(item.full)
                                    }}>
                                    <img src={item.thumb} alt='' />
                                    <p>{item.text}</p>
                                </div>
                            )
                        })}
                    </Slider>
                </div>
                <div className='spacer' />
                <div className='container'>
                    <div className='title-container'>
                        <div className='title'>
                            Renderings
                        </div>
                        <div className='arrows'>
                            <img
                                className='left'
                                src={Arrow}
                                onClick={() => {
                                    slider2.current.slickPrev()
                                }}
                                alt=''
                            />
                            <img
                                className='right'
                                src={Arrow}
                                onClick={() => {
                                    slider2.current.slickNext()
                                }}
                                alt=''
                            />
                        </div>
                    </div>
                    <Slider ref={slider => (slider2.current = slider)} {...settings}>
                        {interior.map((item) => {
                            return (
                                <div className='slide' key={item}
                                    onClick={() => {
                                        setShowLightBox(true)
                                        setLightBoxImage(item.full)
                                    }}>
                                    <img src={item.thumb} alt='' />
                                    <p>{item.text}</p>
                                </div>
                            )
                        })}
                    </Slider>
                </div>
                <LightBox />
            </div>
        </Page>
    )
}

export default Amenities