import React, { useState } from 'react'
import './Header.scss'
import { Fade as Hamburger } from 'hamburger-react'
import Logo from '../../../assets/images/logo.png'
import Builder from '../../../assets/images/builder.png'
import NavButton from '../NavButton/'
import useAppStore from '../../../stores/AppStore'

function Header() {
    const [isOpen, setOpen] = useState(false)
    const navShown = isOpen ? 'show' : 'hide'
    const changePage = useAppStore(state => state.changePage)

    return (
        <div className='header'>
            <div className="logo" onClick={() => {
                changePage(0)
                setOpen(false)
                }}>
                <img src={Logo} alt="The Fifth at Charisma" />
            </div>
            <div className='hamburger'>
                <p onClick={(e) => {
                    setOpen(!isOpen)
                }}>
                    MENU
                </p>
                <Hamburger size={26} color='#ffffff' toggled={isOpen} toggle={setOpen} />
            </div>
            <div className={'nav-menu ' + navShown}>
                <div className='nav-container'>
                    <NavButton id={1} onClick={setOpen} name='floor plans' />
                    <NavButton id={3} onClick={setOpen} name='building amenities' />
                    <NavButton id={5} onClick={setOpen} name='exterior renderings' />
                    <NavButton id={2} onClick={setOpen} name='area amenities map' />
                    <NavButton id={4} onClick={setOpen} name='videos' />
                    <NavButton id={6} onClick={setOpen} name='builder story' />
                    <img src={Builder} alt="Greenpark" />
                </div>
            </div>
        </div>
    )
}

export default Header;