/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export default function GroundModel(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/charisma/ground/ground.gltf')
  return (
    <group ref={group} {...props} dispose={null}>
      <mesh
        geometry={nodes.Ground.geometry}
        material={materials.Stitched}
        material-roughness={1}
        receiveShadow
      />
    </group>
  )
}

useGLTF.preload('/charisma/ground/ground.gltf')
