import React, { useState } from 'react'
import Page from './Page'
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Renders from '../../stores/RenderingStore'
import MappedScrollView from '../../components/Gallery/MappedScrollView'
import Swipe from '../../assets/images/swipe.png'

function Amenities() {
    const floorplans = Renders.floorplans
    const [scrollActive, setScrollActive] = useState(false)
    const [scrollRender, setScrollRender] = useState('')
    const [swipeHidden, setSwipeHidden] = useState(false)
    const alertHidden = swipeHidden ? 'hidden' : ''
    function showScroll(e) {
        e.preventDefault();
        setScrollActive(true)
    }

    function hideScroll(e) {
        e.preventDefault();
        setScrollActive(false)
    }
    const interior = Renders.interior
    var settings = {
        dots: false,
        infinite: true,
        easing: 'ease-in-out',
        speed: 250,
        slidesToShow: 2,
        slidesToScroll: 1,
    }
    return (
        <Page id={3}>
            <MappedScrollView active={scrollActive} hideScroll={hideScroll} render={scrollRender} />
            <div className={"swipe-alert " + alertHidden} onClick={() => setSwipeHidden(!swipeHidden)} onTouchStart={() => setSwipeHidden(!swipeHidden)} >
                <img className='swipe' src={Swipe} alt={''} />
                <p className='swipe'>SWIPE TO VIEW MORE</p>
            </div>
            <div className='amenities'>
                <div className='title'>
                    Amenity Floor Plans
                </div>
                <div>
                    <Slider {...settings}>
                        {floorplans.map((item, index) => {
                            return (
                                <div className='slide' key={item} onClick={(e) => {
                                    showScroll(e)
                                    setScrollRender(item)
                                }}>
                                    <img src={floorplans[index].thumb} alt='' />
                                    <p>{floorplans[index].text}</p>
                                </div>
                            )
                        })}
                    </Slider>
                </div>
                <div className='spacer' />
                <div className='title'>
                    Renderings
                </div>
                <div>
                    <Slider {...settings}>
                        {interior.map((item, index) => {
                            return (
                                <div className='slide' key={item} onClick={(e) => {
                                    showScroll(e)
                                    setScrollRender(item)
                                }}>
                                    <img src={interior[index].thumb} alt='' />
                                    <p>{interior[index].text}</p>
                                </div>
                            )
                        })}
                    </Slider>
                </div>
            </div>
        </Page>
    )
}

export default Amenities