import React, { useRef, useState } from 'react'
import Arrow from '../../assets/images/icons/arrow.webp'
import Swipe from '../../assets/images/swipe.png'

function ScrollView(props) {
    const active = props.active ? 'fade-in' : 'fade-out';
    const imgSrc = props.render !== undefined ? props.render.full : '';
    const ref = useRef();
    const scroll = (scrollOffset) => {
        ref.current.scrollLeft += scrollOffset;
    }
    const [swipeHidden, setSwipeHidden] = useState(false)
    const alertHidden = swipeHidden ? 'hidden' : ''
    return (
        <div className={'gallery ' + active}>
            <div className={"swipe-alert " + alertHidden} onClick={() => setSwipeHidden(!swipeHidden)} onTouchStart={() => setSwipeHidden(!swipeHidden)} >
                <img className='swipe' src={Swipe} alt={''} />
                <p className='swipe'>SWIPE TO VIEW MORE</p>
            </div>
            <div className={'scroll'} ref={ref}>
                <img className='render' src={imgSrc} key={imgSrc} alt={''} />
                <div className='back' onClick={(e) => {
                    props.hideScroll(e)
                    setSwipeHidden(false)
                }}>
                    <img className='arrow' src={Arrow} alt={''} />
                    <a href='/#'>Back</a>
                </div>
                <div className='left' onClick={() => scroll(-5000)}>
                    <img className='nav-arrow' src={Arrow} alt='' height={20} />
                </div>
                <div className='right' onClick={() => scroll(5000)}>
                    <img className='nav-arrow' src={Arrow} alt='' height={20} />
                </div>
            </div>
        </div>
    )
}

export default ScrollView