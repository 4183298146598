import React from 'react'
import './NavButton.scss'
import useAppStore from '../../../stores/AppStore'


function NavButton(props) {
    const pageIndex = useAppStore(state => state.pageIndex)
    const changePage = useAppStore(state => state.changePage)
    const active = props.id === pageIndex ? 'active' : ''
    return (
        <div className='nav-button'>
            <p
                className={active}
                onClick={() => {
                    props.onClick();
                    changePage(props.id);
                }} >
                {props.name}
            </p>
        </div>
    )
}

export default NavButton;