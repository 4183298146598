/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export default function RoadMarkingsModel(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/charisma/roadmarkings/road-markings.gltf')
  return (
    <group ref={group} {...props} dispose={null}>
      <mesh geometry={nodes.Line1789534012.geometry} material={nodes.Line1789534012.material} />
      <mesh geometry={nodes.Line1789534010.geometry} material={nodes.Line1789534010.material} />
      <mesh
        geometry={nodes['Jane_Road_Marings_(dashes)_Rectangle1174510000_290'].geometry}
        material={nodes['Jane_Road_Marings_(dashes)_Rectangle1174510000_290'].material}
      />
      <mesh geometry={nodes.Line1789534011.geometry} material={nodes.Line1789534011.material} />
      <mesh
        geometry={nodes.Line1789534004.geometry}
        material={nodes.Line1789534004.material}
        position={[0, -0.49, 0]}
      />
      <mesh geometry={nodes.Line1789533999.geometry} material={nodes.Line1789533999.material} position={[0, -0.5, 0]} />
      <mesh geometry={nodes.Line1789533998.geometry} material={nodes.Line1789533998.material} />
      <mesh geometry={nodes.Rectangle085.geometry} material={materials['street lines']} />
      <mesh geometry={nodes.Line1789534017.geometry} material={nodes.Line1789534017.material} />
      <mesh geometry={nodes.Line1789534016.geometry} material={nodes.Line1789534016.material} />
      <mesh geometry={nodes.Rectangle1174509998.geometry} material={nodes.Rectangle1174509998.material} />
      <mesh geometry={nodes.Rectangle1174509997.geometry} material={nodes.Rectangle1174509997.material} />
      <mesh geometry={nodes.Object863269646.geometry} material={nodes.Object863269646.material} />
      <mesh geometry={nodes.Object863269647.geometry} material={nodes.Object863269647.material} />
      <mesh geometry={nodes.Object863269648.geometry} material={nodes.Object863269648.material} />
      <mesh
        geometry={nodes.master_line004.geometry}
        material={nodes.master_line004.material}
        position={[0, -0.48, -0.22]}
      />
      <mesh geometry={nodes.Shape019.geometry} material={nodes.Shape019.material} />
      <mesh
        geometry={nodes.Line1789532642.geometry}
        material={nodes.Line1789532642.material}
        position={[-260.35, -0.15, 92.91]}
      />
      <mesh
        geometry={nodes.Line1789534000.geometry}
        material={nodes.Line1789534000.material}
        position={[0, -0.48, -0.22]}
        rotation={[0, 1.45, 0]}
      />
      <mesh geometry={nodes.Shape138.geometry} material={nodes.Shape138.material} />
      <mesh geometry={nodes.Rectangle1174509996.geometry} material={nodes.Rectangle1174509996.material} />
      <mesh geometry={nodes.Rectangle1174509995.geometry} material={nodes.Rectangle1174509995.material} />
      <mesh geometry={nodes.Rectangle1174509991.geometry} material={nodes.Rectangle1174509991.material} />
      <mesh geometry={nodes.Rectangle1174509992.geometry} material={nodes.Rectangle1174509992.material} />
      <mesh geometry={nodes.Rectangle1174509993.geometry} material={nodes.Rectangle1174509993.material} />
      <mesh geometry={nodes.Rectangle1174509994.geometry} material={nodes.Rectangle1174509994.material} />
      <mesh geometry={nodes.Rectangle1174510002.geometry} material={nodes.Rectangle1174510002.material} />
      <mesh geometry={nodes.Rectangle1174510001.geometry} material={nodes.Rectangle1174510001.material} />
      <mesh geometry={nodes.Line1789533997.geometry} material={nodes.Line1789533997.material} rotation={[0, 1.46, 0]} />
      <mesh
        geometry={nodes.Rectangle1174509980.geometry}
        material={nodes.Rectangle1174509980.material}
        rotation={[-Math.PI, 0.12, -Math.PI]}
      />
      <mesh
        geometry={nodes.Rectangle1174509979.geometry}
        material={materials.grass}
        rotation={[-Math.PI, 0.12, -Math.PI]}
      />
    </group>
  )
}

useGLTF.preload('/charisma/roadmarkings/road-markings.gltf')
