import React from 'react'
import Select from 'react-select'

function Dropdown(props) {
    var options = [];
    props.group.floorplans.forEach(item => {
        options.push({ value: item.image, label: item.name })
    });
    const colourStyles = {
        placeholder: (baseStyle) => {
            return {
                ...baseStyle,
                color: '#{$primary}'
            }
        },
        singleValue: (baseStyle) => {
            return {
                ...baseStyle,
                color: '#{$primary}'
            }
        },
        control: (baseStyle) => {
            return {
                ...baseStyle,
                border: '1px solid #dee2e6',
                borderRadius: '0',
                boxShadow: 'none',
                paddingTop: '1rem',
                paddingBottom: '1rem',
                paddingLeft: '1rem',
                letterSpacing: '2.47px',
                margin: '-1px'
            }
        },
    }

    return (
        <div className='dropdown'>
            <Select
                isSearchable={false}
                placeholder={props.group.label}
                styles={colourStyles}
                components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null
                }}
                options={options}
                menuPlacement={props.menuPlacement}
                value = {props.value}
                onChange={(value) => {
                    props.onPlanSelect(value, props.id)
                }}
            />
        </div>
    )
}

export default Dropdown