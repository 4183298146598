import React from 'react'
import useAppStore from '../../../stores/AppStore'
import './Page.scss'

function Page(props) {
    const pageIndex = useAppStore(state => state.pageIndex);
    const active = props.id === pageIndex;
    const fade = active ? 'fade-in' : 'fade-out';
    return (
        <div className={'page ' + fade} style={props.style}>
            {props.children}
        </div>
    )
}

export default Page