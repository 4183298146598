import React, { useState } from 'react'
import Page from '../../Page/'
import useFloorplanStore from '../../../../stores/FloorplanStore'
import useSuiteStore from '../../../../stores/SuiteStore'
import useAppStore from '../../../../stores/AppStore'
import './Floorplans.scss'
import Dropdown from './Dropdown'

import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'

function Floorplans() {
    const plan = useFloorplanStore(state => state.imageUrl)
    const setPlan = useFloorplanStore(state => state.changeUrl)
    const setActive = useFloorplanStore(state => state.setActive)
    const changePage = useAppStore(state => state.changePage)

    const [values, setValues] = useState([null, null, null, null])
    function onChangeValue(value, id) {
        const reset = [null, null, null, null]
        reset[id] = value
        setValues(reset)
        setPlan(value.label, value.value)
        setActive(true)
    }

    const PlaceholderPage = () => {
        if (!plan) {
            return <div className='placeholder'>
                <p>PLEASE SELECT A FLOORPLAN</p>
            </div>
        } else {
            return <></>
        }
    }

    const SelectModelButton = () => {
        if (plan) {
            return <p
                onClick={() => {
                    setActive(true)
                    changePage(0)
                }}>
                view on model
            </p>
        } else {
            return <></>
        }
    }

    const [menuOpen, setMenuOpen] = useState(false)
    const menuShown = menuOpen ? 'show' : 'hide'

    return (
        <Page id={1} style={{ overflow: 'hidden' }}>
            <div className='floorplans'>
                <div className='floorplan-image'>
                    <TransformWrapper>
                        <TransformComponent>
                            <img src={plan} alt=''></img>
                        </TransformComponent>
                    </TransformWrapper>
                </div>
                <PlaceholderPage />
                <div className='buttons'>
                    <SelectModelButton />
                    <p
                        onClick={() => {
                            setMenuOpen(true)
                        }}>
                        select floorplan
                    </p>
                </div>
                <div className={'menu ' + menuShown}>
                    <Dropdown
                        group={useSuiteStore.oneBedroom}
                        id={0}
                        value={values[0]}
                        onPlanSelect={onChangeValue}
                        setMenuOpen={setMenuOpen}
                        menuPlacement={'bottom'}
                    />
                    <Dropdown
                        group={useSuiteStore.oneBedroomDen}
                        id={1}
                        value={values[1]}
                        onPlanSelect={onChangeValue}
                        setMenuOpen={setMenuOpen}
                        menuPlacement={'bottom'}
                    />
                    <Dropdown
                        group={useSuiteStore.twoBedroom}
                        id={2}
                        value={values[2]}
                        onPlanSelect={onChangeValue}
                        setMenuOpen={setMenuOpen}
                        menuPlacement={'bottom'}
                    />
                    <Dropdown
                        group={useSuiteStore.threeBedroom}
                        id={3}
                        value={values[3]}
                        onPlanSelect={onChangeValue}
                        setMenuOpen={setMenuOpen}
                        menuPlacement={'bottom'}
                    />
                    <p onClick={() => {
                        setMenuOpen(false)
                    }}>
                        go back
                    </p>
                </div>
            </div>

        </Page>
    )
}

export default Floorplans