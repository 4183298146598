import React, { useState } from 'react'
import Page from '../../Page/'
import Renders from '../../../../stores/RenderingStore'
import Thumb from '../../../Gallery/Thumb'
import './Renderings.scss'

import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'


function Renderings() {
    const renders = Renders.exterior

    const [LightBoxImage, setLightBoxImage] = useState("")
    const [showLightBox, setShowLightBox] = useState(false)
    const LightBox = () => {
        if (showLightBox) {
            return (
                <div className='lightbox'>
                    <div className='image'>
                        <TransformWrapper>
                            <TransformComponent>
                                <img src={LightBoxImage} alt=''></img>
                            </TransformComponent>
                        </TransformWrapper>
                    </div>
                    <p onClick={() => {
                        setShowLightBox(false)
                    }}>close</p>
                </div>
            )
        } else {
            return <></>
        }
    }

    return (
        <Page id={5}>
            <div className='renderings'>
                <div className='title'>
                    Exterior Renderings
                </div>
                <div className='container'>
                    {renders.map((item, index) => {
                        return <Thumb key={index} render={item}
                            showScroll={() => {
                                setShowLightBox(true)
                                setLightBoxImage(item.full)
                            }}
                        />
                    })}
                </div>
                <LightBox />
            </div>
        </Page>
    )
}

export default Renderings