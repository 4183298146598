/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export default function Model({ ...props }) {
  const group = useRef()
  const { nodes, materials } = useGLTF('charisma/tower/tower2.gltf')

  const concreteRoughness = 0.1
  const concreteMetalness = 0
  const glassRoughness = 0;
  const glassMetalness = 1

  return (
    <group ref={group} {...props} dispose={null}>
      <mesh castShadow receiveShadow
        geometry={nodes.Tower_1.geometry}
        material={materials.Precast}
        material-roughness={concreteRoughness}
        material-metalness={concreteMetalness}
      />
      <mesh castShadow receiveShadow
        geometry={nodes.Tower_2.geometry}
        material={materials.Glass_Railing}
        material-transparent
        material-opacity={0.25}
        material-color={0x000000}
        material-roughness={0}
        material-metalness={0.7}
      />
      <mesh castShadow receiveShadow
        geometry={nodes.Tower_3.geometry}
        material={materials.Glass_Building}
        material-roughness={glassRoughness}
        material-metalness={glassMetalness}
        material-color={0xd4e9ff}
      />
      <mesh castShadow receiveShadow
        geometry={nodes.Tower_4.geometry}
        material={materials['Window Frame']}
      />
      <mesh castShadow receiveShadow
        geometry={nodes.Tower_5.geometry}
        material={materials.Concrete}
        material-roughness={0.75}
      />
      <mesh castShadow receiveShadow
        geometry={nodes.Tower_6.geometry}
        material={materials.Dirt}
      />
      <mesh castShadow receiveShadow
        geometry={nodes.Tower_7.geometry}
        material={materials.Garage}
      />
      <mesh castShadow receiveShadow
        geometry={nodes.Tower_8.geometry}
        material={materials.Panel}
      />
      <mesh castShadow receiveShadow
        geometry={nodes.Tower_9.geometry}
        material={materials.Metal}
      />
      <mesh castShadow receiveShadow
        geometry={nodes.Tower_10.geometry}
        material={materials.Couch}
      />
      <mesh castShadow receiveShadow
        geometry={nodes.Tower_11.geometry}
        material={materials.Marble}
      />
      <mesh castShadow receiveShadow
        geometry={nodes.Tower_12.geometry}
        material={materials['Pool Flooring']}
        material-roughness={0.75}
      />
      <mesh castShadow receiveShadow
        geometry={nodes.Tower_13.geometry}
        material={materials['Pool Tiles']}
      />
      <mesh castShadow receiveShadow
        geometry={nodes.Tower_14.geometry}
        material={materials['Material #711']}
      />
    </group>
  )
}

useGLTF.preload('charisma/tower/tower2.gltf')
