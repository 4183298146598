import React from 'react'
import Page from '../../Page/'
import Amenities from '../../../../assets/images/amenities.webp'
import Legend from '../../../../assets/images/legend_short.png'
import './Map.scss'

import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'

function Map() {
    return (
        <Page id={2}>
            <div className='amenities-map'>
                <div className='title'>
                    Area Amenities Map
                </div>
                <div className='container'>
                    <div className='map'>
                        <TransformWrapper initialScale={0.5} minScale={0.25} maxScale={2} centerZoomedOut centerOnInit>
                            <TransformComponent wrapperStyle={{ maxWidth: "100%", maxHeight: "50vh" }}>
                                <img src={Amenities} alt='Map' />
                            </TransformComponent>
                        </TransformWrapper>
                    </div>
                    <div className='legend'>
                        <img src={Legend} alt='Legend' />
                    </div>
                </div>
            </div>
        </Page>
    )
}

export default Map