import React, { useState } from 'react'
import Page from './Page'
import ReactPlayer from 'react-player'
import MainVideo from '../../assets/videos/main.mp4'
import B757Video from '../../assets/videos/suite_b757.mp4'
import C891Video from '../../assets/videos/suite_c891.mp4'
import useAppStore from '../../stores/AppStore'

function Video() {
    const pageIndex = useAppStore(state => state.pageIndex);
    const [video, setVideo] = useState(MainVideo)
    const active = pageIndex === 4;

    return (
        <Page id={4}>
            <div className='amenities-map'>
                <div className='title'>
                    Video
                </div>
                <div className='container'>
                    <ReactPlayer className="react-player" url={video} controls muted playing={active} width="100%" height="100%" />
                </div>
                <div className='button-container'>
                    <div className={'video-button'}>
                        <p className={video === MainVideo ? 'active' : ''}
                            onClick={() => setVideo(MainVideo)}
                        >
                            overview
                        </p>
                    </div>
                    <div className={'video-button'}>
                        <p className={video === B757Video ? 'active' : ''}
                            onClick={() => setVideo(B757Video)}
                        >
                            one bedroom
                        </p>
                    </div>
                    <div className={'video-button'}>
                        <p className={video === C891Video ? 'active' : ''}
                            onClick={() => setVideo(C891Video)}
                        >
                            two bedroom
                        </p>
                    </div>
                </div>
            </div>
        </Page>
    )
}

export default Video