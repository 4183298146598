import A524 from '../assets/images/suites/A524.webp'
import A527 from '../assets/images/suites/A527.webp'
import A538 from '../assets/images/suites/A538.webp'
import A586 from '../assets/images/suites/A586.webp'
import A596 from '../assets/images/suites/A596.webp'
import A635 from '../assets/images/suites/A635.webp'
import A673 from '../assets/images/suites/A673.webp'
import B650 from '../assets/images/suites/B650.webp'
import B651 from '../assets/images/suites/B651.webp'
import B663 from '../assets/images/suites/B663.webp'
import B664 from '../assets/images/suites/B664.webp'
import B669 from '../assets/images/suites/B669.webp'
import B671 from '../assets/images/suites/B671.webp'
import B695 from '../assets/images/suites/B695.webp'
import B725 from '../assets/images/suites/B725.webp'
import B748 from '../assets/images/suites/B748.webp'
import B750 from '../assets/images/suites/B750.webp'
import B757 from '../assets/images/suites/B757.webp'
import B777 from '../assets/images/suites/B777.webp'
import B783 from '../assets/images/suites/B783.webp'
import B786 from '../assets/images/suites/B786.webp'
import B790 from '../assets/images/suites/B790.webp'
import B810 from '../assets/images/suites/B810.webp'
import C868 from '../assets/images/suites/C868.webp'
import C888 from '../assets/images/suites/C888.webp'
import C891 from '../assets/images/suites/C891.webp'
import C896 from '../assets/images/suites/C896.webp'
import C902 from '../assets/images/suites/C902.webp'
import C970 from '../assets/images/suites/C970.webp'
import C970BF from '../assets/images/suites/C970-BF.webp'
import D1195 from '../assets/images/suites/D1195.webp'
import PHA524 from '../assets/images/suites/PH-A524.webp'
import PHA527 from '../assets/images/suites/PH-A527.webp'
import PHA538 from '../assets/images/suites/PH-A538.webp'
import PHA586 from '../assets/images/suites/PH-A586.webp'
import PHB695 from '../assets/images/suites/PH-B695.webp'
import PHB725 from '../assets/images/suites/PH-B725.webp'
import PHB757 from '../assets/images/suites/PH-B757.webp'
import PHC868 from '../assets/images/suites/PH-C868.webp'
import PHC891 from '../assets/images/suites/PH-C891.webp'
import PHC970 from '../assets/images/suites/PH-C970.webp'


const Suites = {
    oneBedroom: {
        label: '1 Bedroom',
        floorplans: [
            {
                name: 'A524',
                image: A524,
            },
            {
                name: 'A527',
                image: A527,
            },
            {
                name: 'A538',
                image: A538,
            },
            {
                name: 'A586',
                image: A586,
            },
            {
                name: 'A596',
                image: A596,
            },
            {
                name: 'A635',
                image: A635,
            },
            // {
            //     name: 'A673',
            //     image: A673,
            // },
            {
                name: 'PH-A524',
                image: PHA524,
            },
            {
                name: 'PH-A527',
                image: PHA527,
            },
            {
                name: 'PH-A538',
                image: PHA538,
            },
            {
                name: 'PH-A586',
                image: PHA586,
            }
        ]
    }
    ,
    oneBedroomDen: {
        label: '1 Bedroom + Den',
        floorplans: [
            {
                name: 'B650',
                image: B650,
            },
            {
                name: 'B651',
                image: B651,
            },
            {
                name: 'B663',
                image: B663,
            },
            {
                name: 'B664',
                image: B664,
            },
            {
                name: 'B669',
                image: B669,
            },
            {
                name: 'B671',
                image: B671,
            },
            {
                name: 'B695',
                image: B695,
            },
            {
                name: 'B725',
                image: B725,
            },
            {
                name: 'B748',
                image: B748,
            },
            {
                name: 'B750',
                image: B750,
            },
            {
                name: 'B757',
                image: B757,
            },
            {
                name: 'B777',
                image: B777,
            },
            {
                name: 'B783',
                image: B783,
            },
            {
                name: 'B786',
                image: B786,
            },
            {
                name: 'B790',
                image: B790,
            },
            {
                name: 'B810',
                image: B810,
            },
            {
                name: 'PH-B695',
                image: PHB695,
            },
            {
                name: 'PH-B725',
                image: PHB725,
            },
            {
                name: 'PB-B757',
                image: PHB757,
            }
        ]
    },
    twoBedroom: {
        label: '2 Bedroom',
        floorplans: [
            {
                name: 'C868',
                image: C868,
            },
            {
                name: 'C888',
                image: C888,
            },
            {
                name: 'C891',
                image: C891,
            },
            {
                name: 'C896',
                image: C896,
            },
            {
                name: 'C902',
                image: C902,
            },
            {
                name: 'C970',
                image: C970,
            },
            {
                name: 'C970BF',
                image: C970BF,
            },
            {
                name: 'PH-C868',
                image: PHC868,
            },
            {
                name: 'PH-C891',
                image: PHC891,
            },
            {
                name: 'PH-C970',
                image: PHC970,
            }
        ]
    },
    threeBedroom: {
        label: '3 Bedroom',
        floorplans: [
            // {
            //     name: 'D1195',
            //     image: D1195
            // }
        ]
    }
}

export default Suites;