import React, { useState } from 'react'
import Page from './Page'
import SuiteStore from '../../stores/SuiteStore'
import Dropdown from '../Floorplans/Dropdown'
import useFloorplanStore from '../../stores/FloorplanStore'
import useAppStore from '../../stores/AppStore'

function Floorplans() {
    const plan = useFloorplanStore(state => state.imageUrl)
    const setPlan = useFloorplanStore(state => state.changeUrl)
    const setActive = useFloorplanStore(state => state.setActive)
    const changePage = useAppStore(state => state.changePage)
    const shown = plan === '' ? '' : 'hide';

    const [values, setValues] = useState([null, null, null, null])
    function onChangeValue(value, id) {
        const reset = [null, null, null, null]
        reset[id] = value
        setValues(reset)
        setPlan(value.label, value.value)
        setActive(true)
    }

    return (
        <Page id={1}>
            <div className='floorplans'>
                <div className='image-container'>
                    <div className={'placeholder ' + shown}>
                        please select a floorplan
                    </div>
                    <img src={plan} alt='' />
                    <div className={'fp-button ' + (plan === '' ? 'hide' : '')}>
                        <a href="/#" onClick={() => {
                            setActive(true)
                            changePage(0)
                        }}>
                            view on model
                        </a>
                    </div>
                </div>
                <div className='nav-container'>
                    <Dropdown
                        group={SuiteStore.oneBedroom}
                        id={0}
                        value={values[0]}
                        onPlanSelect={onChangeValue}
                        menuPlacement={'top'}
                    />
                    <Dropdown
                        group={SuiteStore.oneBedroomDen}
                        id={1}
                        value={values[1]}
                        onPlanSelect={onChangeValue}
                        menuPlacement={'top'}
                    />
                    <Dropdown
                        group={SuiteStore.twoBedroom}
                        id={2}
                        value={values[2]}
                        onPlanSelect={onChangeValue}
                        menuPlacement={'top'}
                    />
                </div>
            </div>
        </Page>
    )
}

export default Floorplans
