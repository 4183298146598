import React, { useEffect, useRef, useState } from 'react'
import Arrow from '../../assets/images/icons/arrow.webp'
import Swipe from '../../assets/images/swipe.png'

function MappedScrollView(props) {
    const active = props.active ? 'fade-in' : 'fade-out';
    const [swipeHidden, setSwipeHidden] = useState(false)
    const alertHidden = swipeHidden ? 'hidden' : ''
    const [isSubImage, setIsSubImage] = useState(false)
    const [image, setImage] = useState('')
    const [subImage, setSubImage] = useState('')
    // var imgSrc = props.render !== undefined ? props.render.full : '';
    const ref = useRef();
    const scroll = (scrollOffset) => {
        ref.current.scrollLeft += scrollOffset;
    }

    useEffect(() => {
        isSubImage ? setImage(subImage) : setImage(props.render)
    }, [isSubImage, subImage, props.render])

    return (
        <div className={'gallery ' + active}>
            <div className={"swipe-alert " + alertHidden} onClick={() => setSwipeHidden(!swipeHidden)} onTouchStart={() => setSwipeHidden(!swipeHidden)} >
                <img className='swipe' src={Swipe} alt={''} />
                <p className='swipe'>SWIPE TO VIEW MORE</p>
            </div>
            <div className={'scroll'} ref={ref}>
                {props.render.hasOwnProperty('image_map') ?
                    <>
                        <img className='render' src={image.full} key={image.id} alt={''} useMap='#image-map' />
                        <map name='image-map'>
                            {props.render.image_map.map((item, index) => {
                                return <area key={index} alt={item.alt} coords={item.coords} shape={item.shape}
                                    onClick={() => {
                                        if (isSubImage) return;
                                        setSwipeHidden(false)
                                        setSubImage(item)
                                        setIsSubImage(true)
                                    }}
                                />
                            })}
                        </map>
                    </>
                    :
                    <img className='render' src={image.full} key={image.id} alt={''} />
                }
                <div className='back' onClick={(e) => {
                    setSwipeHidden(false)
                    if (isSubImage) {
                        setIsSubImage(false)
                    } else {
                        props.hideScroll(e)
                    }
                }}>
                    <img className='arrow' src={Arrow} alt={''} />
                    <a href='/#'>Back</a>
                </div>
                <div className='left' onClick={() => scroll(-5000)}>
                    <img className='nav-arrow' src={Arrow} alt='' height={20} />
                </div>
                <div className='right' onClick={() => scroll(5000)}>
                    <img className='nav-arrow' src={Arrow} alt='' height={20} />
                </div>
            </div>
        </div>
    )
}

export default MappedScrollView