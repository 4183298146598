import './App.scss';
import { BrowserView, isMobile } from 'react-device-detect'
import Header from './components/Header/Header'
import Footer from './components/Footer/Footer'
import Content from './components/Content/Content';
import MobileHeader from './components/Mobile/Header'
import MobileContent from './components/Mobile/Content'

function MobilePage() {
  if (isMobile) {
    return (
      <div className="mobile">
        <MobileHeader />
        <MobileContent />
      </div>
    )
  } else {
    return <></>
  }
}

function App() {
  return (
    <div className="app" onContextMenu={(e) => {
      e.preventDefault()
    }}>
      <BrowserView>
        <div className="desktop">
          <Header />
          <Content />
          <Footer />
        </div>
      </BrowserView>
      <MobilePage />
    </div>
  );
}

export default App;
