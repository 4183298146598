import React from 'react'
import Page from './Page'
import builderImage from '../../assets/images/story.webp'

function Builder() {
    return (
        <Page id={6}>
            <div className='builder'>
                <img src={builderImage} alt='' />
            </div>
        </Page>
    )
}

export default Builder