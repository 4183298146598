import React from 'react'
import logo from '../../assets/images/logo.webp'
import NavButton from '../NavButton/NavButton'
import useAppStore from '../../stores/AppStore'
import useFloorplanStore from '../../stores/FloorplanStore'

function Footer() {
    const changePage = useAppStore(state => state.changePage)
    const setActive = useFloorplanStore(state => state.setActive)
    return (
        <div className='footer'>
            <div className='container'>
                <div className='logo'>
                    <a href='/#'>
                        <img
                            src={logo}
                            alt='The 5th at Charisma'
                            onClick={() => {
                                changePage(0)
                                setActive(false)
                            }}
                        />
                    </a>
                </div>
                <div className='nav'>
                    <div className='nav-container'>
                        <NavButton id={1} name='floor plans' />
                        <NavButton id={2} name='area amenities map' />
                        <NavButton id={3} name='building amenities' />
                        <NavButton id={4} name='videos' />
                        <NavButton id={5} name='exterior renderings' />
                        <NavButton id={6} name='builder story' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer