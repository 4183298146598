/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export default function Model({ ...props }) {
    const group = useRef()
    const { nodes, materials } = useGLTF('charisma/footprints/logofootprint.gltf')
    return (
        <group ref={group} {...props} dispose={null}>
            <mesh
                castShadow
                receiveShadow
                geometry={nodes.Phase_1n2_Footprint_1.geometry}
                material={materials['Regular Orange']}
            />
            <mesh
                castShadow
                receiveShadow
                geometry={nodes.Phase_1n2_Footprint_2.geometry}
                material={materials['Lighter Orange']}
            />
            <mesh
                castShadow
                receiveShadow
                geometry={nodes.Phase_1n2_Footprint_3.geometry}
                material={materials['Regular Green']}
            />
            <mesh
                castShadow
                receiveShadow
                geometry={nodes.Phase_1n2_Footprint_4.geometry}
                material={materials['Lighter Green']}
            />
            <mesh
                position={[0, 0.2, 0]}
                castShadow
                receiveShadow
                geometry={nodes.Logo_rooftop.geometry}
                material={materials['Logo Gold']}
                rotation={[Math.PI, 0, Math.PI]}
                scale={2.4}
            />
        </group>
    )
}

useGLTF.preload('charisma/footprints/logofootprint.gltf')
