/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export default function PlantersModel(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/charisma/planters/test.gltf')
  return (
    <group ref={group} {...props} dispose={null}>
      <mesh receiveShadow geometry={nodes.raised_planter_045_1.geometry} material={nodes.raised_planter_045_1.material} material-roughness={1} />
      <mesh receiveShadow geometry={nodes.raised_planter_045_2.geometry} material={nodes.raised_planter_045_2.material} material-roughness={1} />
      <mesh receiveShadow geometry={nodes.Box052_1.geometry} material={nodes.Box052_1.material} material-roughness={1} />
      <mesh receiveShadow geometry={nodes.Box052_2.geometry} material={nodes.Box052_2.material} material-roughness={1} />
      <mesh receiveShadow geometry={nodes.Box406_1.geometry} material={nodes.Box406_1.material} material-roughness={1} />
      <mesh receiveShadow geometry={nodes.Box406_2.geometry} material={nodes.Box406_2.material} material-roughness={1} />
      <mesh receiveShadow geometry={nodes.Object863269653_1.geometry} material={nodes.Object863269653_1.material} material-roughness={1} />
      <mesh receiveShadow geometry={nodes.Object863269653_2.geometry} material={nodes.Object863269653_2.material} material-roughness={1} />
      <mesh receiveShadow geometry={nodes.Object863269654_1.geometry} material={materials['Vase black']} material-roughness={1} />
      <mesh receiveShadow geometry={nodes.Object863269654_2.geometry} material={nodes.Object863269654_2.material} material-roughness={1} />
    </group>
  )
}

useGLTF.preload('/charisma/planters/test.gltf')
