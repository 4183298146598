import React from 'react'

function Title() {
    return (
        <div className='title'>
            THE 5TH AT CHARISMA
        </div>
    )
}

function Header() {
    return (
        <div className='header'>
            <Title />
        </div>
    )
}

export default Header