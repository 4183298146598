/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export default function Model(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/charisma/park/park.gltf')
  return (
    <group ref={group} {...props} dispose={null}>
      <group position={[81.57, 0.26, 165.36]}>
        <mesh
          geometry={nodes.Railing001.geometry}
          material={nodes.Railing001.material}
          material-roughness={1}
          position={[-9.11, 0.98, -3.11]}
          rotation={[0, -0.02, 0]}
          scale={[0.96, 1, 1]}
        />
        <mesh
          geometry={nodes.Rectangle007.geometry}
          material={nodes.Rectangle007.material}
          material-roughness={1}
          position={[-3.75, -0.26, 1.69]}
          rotation={[0, 0.51, 0]}
          scale={[0.65, 1, 0.65]}
        />
      </group>
      <mesh
        geometry={nodes.Object122.geometry}
        material={nodes.Object122.material}
        material-roughness={1}
        position={[76.25, 1.86, 151]}
        rotation={[0, 1.39, 0]}
        scale={[0.87, 1, 0.91]}
      />
      <mesh
        geometry={nodes.Object114.geometry}
        material={nodes.Object114.material}
        material-roughness={1}
        position={[56.21, 2.75, 146.27]}
        rotation={[Math.PI / 2, 0, 1.74]}
        scale={[0.78, -0.82, 0.9]}
      />
      <mesh
        geometry={nodes.Object113.geometry}
        material={nodes.Object113.material}
        material-roughness={1}
        position={[55.81, 3.27, 146.2]}
        rotation={[Math.PI / 2, 0, 1.74]}
        scale={[0.79, -0.82, 0.9]}
      />
      <mesh
        geometry={nodes.Object112.geometry}
        material={nodes.Object112.material}
        material-roughness={1}
        position={[55.39, 0.9, 146.13]}
        rotation={[Math.PI / 2, 0, 1.74]}
        scale={[0.87, -0.91, 1]}
      />
      <mesh
        geometry={nodes.Object111.geometry}
        material={nodes.Object111.material}
        material-roughness={1}
        position={[55.1, 1.76, 146.02]}
        rotation={[0, 1.4, 0]}
        scale={[0.87, 1, 0.91]}
      />
      <mesh
        geometry={nodes.Object110.geometry}
        material={nodes.Object110.material}
        material-roughness={1}
        position={[55.84, 2.9, 146.22]}
        rotation={[Math.PI / 2, 0, 1.75]}
        scale={[0.87, 0.91, 1]}
      />
      <mesh
        geometry={nodes.Object105.geometry}
        material={nodes.Object105.material}
        material-roughness={1}
        position={[73.74, 2.9, 149.47]}
        rotation={[Math.PI / 2, 0, 1.74]}
        scale={[0.87, -0.91, 1]}
      />
      <mesh
        geometry={nodes.Object109.geometry}
        material={nodes.Object109.material}
        material-roughness={1}
        position={[73.37, 2.75, 149.39]}
        rotation={[Math.PI / 2, 0, 1.75]}
        scale={[0.78, 0.82, 0.9]}
      />
      <mesh
        geometry={nodes.Object108.geometry}
        material={nodes.Object108.material}
        material-roughness={1}
        position={[73.77, 3.27, 149.46]}
        rotation={[Math.PI / 2, 0, 1.75]}
        scale={[0.79, 0.82, 0.9]}
      />
      <mesh
        geometry={nodes.Object106.geometry}
        material={nodes.Object106.material}
        material-roughness={1}
        position={[74.51, 1.76, 149.53]}
        rotation={[-Math.PI, -1.39, 0]}
        scale={[0.87, -1, 0.91]}
      />
      <mesh
        geometry={nodes.Object107.geometry}
        material={nodes.Object107.material}
        material-roughness={1}
        position={[74.19, 0.9, 149.54]}
        rotation={[Math.PI / 2, 0, 1.75]}
        scale={[0.87, 0.91, 1]}
      />
      <mesh
        geometry={nodes.Object104.geometry}
        material={nodes.Object104.material}
        material-roughness={1}
        rotation={[0, 1.39, Math.PI]}
        scale={[0.87, -1, 0.91]}
      />
      <mesh
        geometry={nodes.archmodels81_006_01.geometry}
        material={materials.bcourt}
        material-roughness={1}
        position={[64.74, 0.03, 147.95]}
        rotation={[0, 1.39, 0]}
        scale={[0.87, 1, 0.91]}
      />
      <mesh
        geometry={nodes.ph2_park001.geometry}
        material={materials.Stripxz}
        material-roughness={1}
        position={[-163.46, -0.04, 34.6]}
      />
      <group position={[56.94, 0, 96.99]}>
        <mesh geometry={nodes.park_walks_pavement002_1.geometry} material={materials.Tilesss} material-roughness={1} />
        <mesh geometry={nodes.park_walks_pavement002_2.geometry} material={nodes.park_walks_pavement002_2.material} material-roughness={1} />
      </group>
      <mesh
        geometry={nodes.ground_mulch.geometry}
        material={nodes.ground_mulch.material}
        position={[-33.3, 0.01, 88.14]}
        receiveShadow
      />
      <mesh geometry={nodes.Line1009.geometry} material={nodes.Line1009.material} material-roughness={1} position={[-14.9, 0, 141.63]} />
      <mesh geometry={nodes.Shape251.geometry} material={nodes.Shape251.material} material-roughness={1} position={[10.35, -2.5, 90.67]} />
      <mesh
        geometry={nodes.Line978.geometry}
        material={materials['Material #-2147483422']}
        material-roughness={1}
        position={[-0.35, 0.4, 13.29]}
      />
      <mesh geometry={nodes.Shape250.geometry} material={nodes.Shape250.material} material-roughness={1} position={[10.35, -2.46, 90.67]} />
      <mesh geometry={nodes.water_play_area.geometry} material={materials.Pad2} material-roughness={1} position={[10.85, 0.09, 63.48]} />
      <mesh geometry={nodes.Junior_child_play_area.geometry} material={materials.Pad1} material-roughness={1} position={[7.79, 0.08, 84.67]} />
      <mesh
        geometry={nodes.Senior_child_play_area.geometry}
        material={materials.Pad3}
        material-roughness={1}
        position={[22.77, 0.08, 78.12]}
      />
      <mesh
        geometry={nodes.Senior_child_play_areacurb.geometry}
        material={nodes.Senior_child_play_areacurb.material}
        material-roughness={1}
        position={[22.77, 0, 78.12]}
        scale={[1, 1.39, 1]}
      />
      <mesh geometry={nodes.Shape243.geometry} material={nodes.Shape243.material} position={[66.63, -0.08, 147.56]} />
    </group>
  )
}

useGLTF.preload('/charisma/park/park.gltf')
