import React, { useState } from 'react'
import ReactPlayer from 'react-player'
import Page from '../../Page/'
import MainVideo from '../../../../assets/videos/main.mp4'
import B757Video from '../../../../assets/videos/suite_b757.mp4'
import C891Video from '../../../../assets/videos/suite_c891.mp4'
import './Video.scss'

function Video() {
    const [video, setVideo] = useState(MainVideo)

    return (
        <Page id={4}>
            <div className='video'>
                <div className='title'>
                    Video
                </div>
                <div className='container'>
                    <ReactPlayer className="react-player" url={video} controls muted playing width="100%" height="100%" />
                </div>
                <div className='button-container'>
                    <div className={'video-button'}>
                        <p className={video === MainVideo ? 'active' : ''}
                            onClick={() => setVideo(MainVideo)}
                        >
                            overview
                        </p>
                    </div>
                    <div className={'video-button'}>
                        <p className={video === B757Video ? 'active' : ''}
                            onClick={() => setVideo(B757Video)}
                        >
                            one bedroom
                        </p>
                    </div>
                    <div className={'video-button'}>
                        <p className={video === C891Video ? 'active' : ''}
                            onClick={() => setVideo(C891Video)}
                        >
                            two bedroom
                        </p>
                    </div>
                </div>
            </div>
        </Page>
    )
}

export default Video