import React from 'react'
import './Content.scss'
import Floorplans from '../Pages/Floorplans'
import Amenities from '../Pages/Amenities'
import Renderings from '../Pages/Renderings'
import Map from '../Pages/Map'
import Video from '../Pages/Video'
import Builder from '../Pages/Builder'
import Viewer from '../../../Viewer'
import useAppStore from '../../../stores/AppStore'


function Content() {
    const pageIndex = useAppStore(state => state.pageIndex);
    const CurrentPage = () => {
        switch (pageIndex) {
            case 0:
                return <Viewer />
            case 1:
                return <Floorplans />
            case 2:
                return <Map />
            case 3:
                return <Amenities />
            case 4:
                return <Video /> 
            case 5:
                return <Renderings />
            case 6:
                return <Builder />  
            default:
                return <></>
        }
    }
    return (
        <div className='content'>
            <CurrentPage show={true} />
        </div>
    )
}

export default Content;