/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export default function Model(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/charisma/road/road-v2.gltf')
  return (
    <group ref={group} {...props} dispose={null}>
      <mesh
        geometry={nodes.ph2_asphalt.geometry}
        material={nodes.ph2_asphalt.material}
        material-roughness={1}
        receiveShadow
      />
      <mesh
        geometry={nodes.Object863269649.geometry}
        material={materials.Roads}
        material-roughness={1}
        receiveShadow
      />
      <mesh
        geometry={nodes.Object863269650.geometry}
        material={materials['interlocking road 2']}
        material-roughness={1}
        receiveShadow
      />
      <mesh
        geometry={nodes.Object863269651.geometry}
        material={materials.Stripxz}
        material-roughness={1}
        receiveShadow
      />
      <mesh
        geometry={nodes['232'].geometry}
        material={materials.Grass}
        material-roughness={1}
        receiveShadow
      />
    </group>
  )
}

useGLTF.preload('/charisma/road/road-v2.gltf')
