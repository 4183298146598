import React from 'react'
import Floorplans from '../Pages/Floorplans'
import Map from '../Pages/Map'
import Video from '../Pages/Video'
import Renderings from '../Pages/Renderings'
import Builder from '../Pages/Builder'
import Amenities from '../Pages/Amenities'
import Viewer from '../../Viewer'
import useAppStore from '../../stores/AppStore'
import Screensaver from '../Pages/Screensaver'
import { useIdle } from 'react-use'

function Content() {
    const pageIndex = useAppStore(state => state.pageIndex)
    const fade = pageIndex > 0 ? 'fade-in' : 'fade-out'

    const isIdle = useIdle(300e3, true);

    const CurrentPage = () => {
        if (isIdle) {
            return <Screensaver />
        } else {
            switch (pageIndex) {
                case 0:
                    return <></>
                case 1:
                    return <Floorplans />
                case 2:
                    return <Map />
                case 3:
                    return <Amenities />
                case 4:
                    return <Video />
                case 5:
                    return <Renderings />
                case 6:
                    return <Builder />
                case 7:
                    return <Screensaver />
                default:
                    return <></>
            }
        }
    }

    return (
        <div className='content'>
            <Viewer />
            <div className={'page ' + fade} />
            <CurrentPage />
            {/* <Viewer />
            <div className={'page ' + fade} />
            <Floorplans />
            <Map />
            <Amenities />
            <Video />
            <Renderings />
            <Builder /> */}
        </div>
    )
}

export default Content