import create from 'zustand'

const useStore = create(set => ({
    active: false,
    suiteName: '',
    imageUrl: '',
    setActive: (status) => set({ active: status}),
    changeUrl: (name, url) => set({ suiteName: name, imageUrl: url })
}))

export default useStore