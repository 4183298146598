import React from 'react'

function Thumb(props) {
    return (
        <div className='thumb'>
            <img src={props.render.thumb} alt='' onClick={props.showScroll}/>
        </div>
    )
}

export default Thumb